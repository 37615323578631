import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 9px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(13px, -4px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      backgroundColor: '#fff',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

export default CssTextField;
