import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuList,
  MenuItem,
  Card,
  CardContent,
  Box,
  Dialog,
  Typography,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { COLORS } from 'constants/colors';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { configure } from 'react-hotkeys';
import history from 'utils/history';
import s from 'routes/home/Home.css';
import { useDispatch, useSelector } from 'react-redux';
import apiRequest from 'utils/api';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import { updateModulesStore } from 'actions/modules';
import NoResults from '/public/images/noResults.png';

//-----STYLED COMPONENTS-----------------------------------------------

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#222',
        borderWidth: '1px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const StyledReportMenuItem = withStyles((theme) => ({
  root: {
    padding: '16px',
    borderRadius: '6px',
    fontSize: '13px',
    fontFamily: 'Work Sans',
    color: '#5d5d5d',
    '&.Mui-selected': {
      backgroundColor: '#dfffbe !important',
      color: '#222',
      fontWeight: '500',
    },
    '&.MuiListItem-root.Mui-selected,.Mui-selected:hover': {
      backgroundColor: 'none',
    },
  },
}))(MenuItem);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '12px 16px',
  },
  closeButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    color: '#222222',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, searchInput, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Grid item>
          <p style={{ margin: '0', fontSize: '15px', fontWeight: '500' }}>
            {children}
          </p>
        </Grid>
        <Grid item>
          {searchInput}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    height: '640px',
  },
}))(MuiDialogContent);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ width: 'calc(100% - 16px)', padding: '4px 16px 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function ReportsDialog({
  open,
  setOpen = () => { },
  warnNavigationAway = false,
  navigateAway = () => { },
}) {
  useStyles(s);

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedReportTab, setSelectedReportTab] = React.useState(0);
  const modules = useSelector((state) => state.modules);
  const home = useSelector((state) => state.home);

  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);
  const [allModules, setAllModules] = React.useState([]);
  const [allReports, setAllReports] = React.useState([]);
  const [reportsMenu, setReportsMenu] = React.useState();
  const [reportSearch, setReportSearch] = React.useState('');
  const [favourites, setFavourites] = React.useState([]);

  React.useEffect(() => {
    if (modules?.modules?.length > 0) {
      let all_modules = [...modules?.modules];
      let reports_index = all_modules.findIndex(
        (obj) => obj.title == 'Reports',
      );
      if (reports_index != -1) {
        let all_reports = [];
        modules?.reports?.forEach((group) => {
          if (Object.keys(group).includes('reports'))
            all_reports.splice(all_reports?.length, 0, ...group?.reports);
          if (Object.keys(group).includes('sub_group'))
            group?.sub_group?.forEach((subGroup) => {
              all_reports.splice(all_reports?.length, 0, ...subGroup?.reports);
            });
        });
        let reports_obj = reports_index != -1 ? all_modules[reports_index] : {};
        all_modules[reports_index] = {
          ...reports_obj,
          submenu: all_reports,
        };
        setAllModules(all_modules);
        setAllReports(all_reports);
      }
    }
  }, [modules?.modules]);
  React.useEffect(() => {
    if (modules?.favourites?.length > 0)
      setFavourites([...modules?.favourites]);
  }, [modules?.favourites]);
  React.useEffect(() => {
    if (modules?.reports?.length > 0) setReportsMenu([...modules?.reports]);
  }, [modules?.reports]);

  function openReport(reportRoute) {
    dialogClose();
    setTimeout(() => {
      history.push(reportRoute);
    }, 100);
  }
  function searchReports() {
    let search_results = allReports
      ?.filter((eachCard) =>
        eachCard.title.toLowerCase().includes(reportSearch.toLowerCase()),
      )
      ?.sort(function (a, b) {
        const aStarts = a?.title
          ?.toLowerCase()
          .startsWith(reportSearch.toLowerCase());
        const bStarts = b?.title
          ?.toLowerCase()
          .startsWith(reportSearch.toLowerCase());
        if (aStarts && bStarts)
          return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
        if (aStarts && !bStarts) return -1;
        if (!aStarts && bStarts) return 1;
        return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
      });
    return search_results;
  }
  function addToFavourites(module) {
    if (favourites.filter((f) => f.title == module.title)?.length > 0) {
      enqueueSnackbar(`Already added to favourites`, {
        variant: 'error',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else {
      if (favourites?.length >= 12) {
        enqueueSnackbar(`Max favorites limit reached (Max:12)`, {
          variant: 'error',
          autoHideDuration: 1500,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        return 0;
      }
      let favs = [...favourites];
      favs.push(module);
      if (module?.id) {
        setFavourites(favs);
        updateFavourites(module, 'add', favs);
      }
    }
  }
  const updateFavourites = async (module, type, newState) => {
    try {
      let body = {
        card_id: module?.id,
        type: type,
        is_report: module?.is_report || false,
      };
      const res = await apiRequest(`api/update_favourites/`, body, 'POST');
      dispatch(
        updateModulesStore({
          favourites: newState,
        }),
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Could not update favourites`, {
        variant: 'error',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      if (type == 'add') {
        dispatch(
          updateModulesStore({
            favourites: newState?.filter((card) => card.id != module?.id),
          }),
        );
      }
      if (type == 'remove') {
        let favs = [...newState];
        favs.push(module);
        dispatch(
          updateModulesStore({
            favourites: favs,
          }),
        );
      }
    }
  };

  const keyMap = {};
  const handlers = {};
  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: (event) => {
      const { target } = event;

      if (target && target.tagName) {
        return target.isContentEditable;
      }
      return false;
    },
  });

  function dialogClose() {
    setOpen(false);
    setReportSearch('');
  }

  const renderReportsDialog = (input, type) => {
    function generateCard(eachCard) {
      return (
        <Grid item md={3}>
          <Card
            className="homeModuleCard"
            id="variation-reports-dialog"
            variant="outlined"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 8px 16px 8px',
              padding: '0 8px',
            }}
            onClick={(e) => {
              warnNavigationAway
                ? navigateAway(eachCard.route, openReport)
                : openReport(eachCard.route);
              e.stopPropagation();
            }}
          >
            <CardContent className={s.card_content_shortcuts_trail}>
              <Grid container style={{ height: '100%' }}>
                <Grid container item md={11} sm={11} alignItems="center">
                  <Grid
                    container
                    item
                    style={{
                      width: 'calc(100% - 10px)',
                      height: '100%',
                    }}
                    alignContent="center"
                  >
                    <p className={s.title_trail}>{eachCard?.title}</p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={1}
                  sm={1}
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    {eachCard.shortcut == '*' ? (
                      <span className={s.newIndication} />
                    ) : (
                      <div className={s.keyboardShortcutContainer}>
                        <h4
                          style={{
                            color: COLORS.app_secondary,
                            margin: '0px',
                          }}
                        >
                          {eachCard?.shortcut ? (
                            eachCard.shortcut
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </h4>
                      </div>
                    )}
                  </Grid>
                  <Grid item className="homeModuleCardFavorite">
                    <Tooltip
                      placement="top"
                      title={<p className="tooltip">Add to favourites</p>}
                      enterDelay={200}
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <IconButton
                        style={{ padding: '0', marginBottom: '5px' }}
                        onClick={(e) => {
                          addToFavourites(eachCard);
                          // Prevent trigger of parent event (clicking card)
                          if (!e) var e = window.event;
                          e.cancelBubble = true;
                          e.stopPropagation();
                        }}
                      >
                        <StarBorderRoundedIcon
                          style={{
                            fontSize: '15px',
                            fill: '#eebc3a',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    }
    function generateTabContent(group) {
      return (
        <div
          style={{
            backgroundColor: '#f8f8f8',
            width: 'calc(100% - 36px)',
            height: 'calc(100% - 48px)',
            overflowY: 'auto',
            border: 'solid 1px #ededed',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 10px 0',
            margin: '16px 0',
          }}
        >
          {group && Object.keys(group)?.includes('sub_group')
            ? group?.sub_group?.map((subGroup, subIndex) => {
              return (
                <>
                  <p
                    className={s.reportSubGroupTitle}
                    style={{ marginTop: subIndex == 0 ? '0px' : '24px' }}
                  >
                    {subGroup?.group_name}
                  </p>
                  <Grid container>
                    {subGroup?.reports?.map((eachCard) => {
                      return generateCard(eachCard);
                    })}
                  </Grid>
                </>
              );
            })
            : ''}
          {group && Object.keys(group)?.includes('reports') ? (
            <>
              <p
                className={s.reportSubGroupTitle}
                style={{
                  marginTop:
                    group && Object.keys(group)?.includes('sub_group')
                      ? '24px'
                      : '0px',
                }}
              >
                {group && Object.keys(group)?.includes('sub_group')
                  ? 'Others'
                  : ''}
              </p>
              <Grid container>
                {group?.reports?.map((eachCard) => {
                  return generateCard(eachCard);
                })}
              </Grid>
            </>
          ) : (
            ''
          )}
        </div>
      );
    }
    function generateSearchResultsContent(results) {
      return (
        <div
          style={{
            backgroundColor: results?.length > 0 ? '#f8f8f8' : '',
            width: 'calc(100% - 36px)',
            height: 'calc(100% - 48px)',
            overflowY: 'auto',
            border: 'solid 1px #ededed',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 10px 0',
            margin: '16px 0',
            // alignContent: results?.length > 0 ? 'flex-start' : 'center',
            justifyContent: results?.length > 0 ? 'flex-start' : 'center',
          }}
        >
          {results?.length > 0 ? (
            <Grid container>
              {results?.map((eachCard) => {
                return generateCard(eachCard);
              })}
            </Grid>
          ) : (
            <Grid container justifyContent="center">
              <img src={NoResults} style={{ height: '128px' }} />
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '12px 0 0',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                No Report Found
              </p>
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '10px 0 0',
                  fontSize: '13px',
                  fontWeight: '400',
                  color: '#787878',
                }}
              >
                We couldn't find any report matching your search. Try something
                different
              </p>
            </Grid>
          )}
        </div>
      );
    }

    return (
      <>
        <Grid
          item
          style={{
            height: '100%',
            overflowY: 'auto',
            flexBasis: '20%',
          }}
        >
          <MenuList
            variant="selectedMenu"
            style={{ padding: '16px', background: 'none' }}
            disableRipple
          >
            {type == 0 ? (
              input?.map((group, index) => (
                <StyledReportMenuItem
                  disableRipple
                  key={index}
                  selected={index === selectedReportTab}
                  onClick={(e) => {
                    setSelectedReportTab(index);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    {group?.group_name}
                  </div>
                </StyledReportMenuItem>
              ))
            ) : (
              <StyledReportMenuItem disableRipple key={0} selected={true}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  Search Results
                </div>
              </StyledReportMenuItem>
            )}
          </MenuList>
        </Grid>
        <Grid item style={{ height: '100%', flexBasis: '80%' }}>
          {type == 0
            ? generateTabContent(input?.[selectedReportTab])
            : generateSearchResultsContent(input)}
        </Grid>
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      onClose={dialogClose}
      open={open ?? false}
      maxWidth="lg"
      id="moreReportsDialog"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={dialogClose}
        style={{
          padding: '12px',
          backgroundColor: '#c7eafa',
          color: '#003471',
        }}
        searchInput={
          <CssTextField
            style={{
              width: '384px',
              marginRight: '36px',
            }}
            autoFocus
            className={s.inputfieldset}
            placeholder="Search Reports"
            variant="outlined"
            margin="normal"
            type="text"
            autoComplete="off"
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            contentEditable
            suppressContentEditableWarning
            value={reportSearch || ''}
            onChange={(event) => setReportSearch(event.target.value)}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
              }
            }}
          />
        }
      >
        Reports&nbsp;{`(${allReports?.length})`}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'clip' }}>
        <Grid
          container
          style={{
            height: '100%',
            overflowY: 'hidden',
            padding: '0',
          }}
        >
          {reportSearch
            ? renderReportsDialog(searchReports(), 1)
            : renderReportsDialog(reportsMenu, 0)}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ReportsDialog.propTypes = {};
