import React, { useState } from 'react';
import history from 'utils/history';
import { useDispatch, useSelector } from 'react-redux';
import apiRequest from 'utils/api';

export function useInventoryManagement() {
  const entryState = useSelector((state) => state.entrystate);

  const updateAdminEditLockFlag = async (
    moduleKey = '',
    route = '',
    callBackFn = null,
  ) => {
    try {
      await apiRequest(
        `/update_lock_status/`,
        {
          id: entryState?.id,
          tran_type: moduleKey,
          type: 'unlock',
        },
        'POST',
      ).then((response) => {
        if (route !== null && callBackFn === null) {
          history.push(route);
          return;
        }

        if (callBackFn !== null) {
          callBackFn(route);
          // if route is not passed then only check if route is null if it is then redirect to home
          if (route === null) {
            history.push('/home');
          }
        }
      });
    } catch (error) {
      alert(error.message);
    }
  };
  return {
    updateAdminEditLockFlag,
  };
}
