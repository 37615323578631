import React from 'react';
import { Line } from 'react-chartjs-2';

export default function AreaChart({ data, eid, styles = {} }) {
  const data_c = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient_first = ctx.createLinearGradient(0, 0, 0, 150);
    gradient_first.addColorStop(0, '#FF2828');
    gradient_first.addColorStop(1, '#FFFFFF');

    const gradient_sec = ctx.createLinearGradient(0, 0, 0, 150);
    gradient_sec.addColorStop(0, '#A3CDFE');
    gradient_sec.addColorStop(1, '#FFFFFF');
    // console.log(data, 'data==========', eid);
    const labels = data?.labels || [
      '9 AM',
      '11 AM',
      '1 PM',
      '3 PM',
      '5 PM',
      '7 PM',
      '9 PM',
    ];
    const presentData = data?.presentData.map((num) => parseInt(num) || 0) || [
      0,
    ];
    const pastData = data?.pastData.map((num) => parseInt(num) || 0) || [0];

    return {
      labels: labels,
      datasets: [
        {
          label: 'First dataset',
          backgroundColor: gradient_first, // Put the gradient here as a fill color
          borderColor: '#FF2828',
          borderWidth: 2,
          pointRadius: 0,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          },
          //   pointColor: '#fff',
          //   pointStrokeColor: '#ff6c23',
          //   pointHighlightFill: '#fff',
          //   pointHighlightStroke: '#ff6c23',
          data: presentData,
        },
        {
          label: 'second dataset',
          data: pastData,
          backgroundColor: gradient_sec, // Put the gradient here as a fill color
          borderColor: '#2981E9',
          borderWidth: 2,
          pointRadius: 0,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          },
          //   borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    // datasetStrokeWidth: 3,
    // pointDotStrokeWidth: 4,
    // scaleLabel: "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          },
        },
      ],
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      //   x: {
      //     grid: {
      //       display: false,
      //     },
      //   },
      //   xAxes: [
      //     {
      //       gridLines: {
      //         drawOnChartArea: false,
      //       },
      //     },
      //   ],
      yAxes: [
        {
          display: false,
          gridLines: {
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            padding: 10,
          },
        },
      ],
    },
  };

  //   const canvas = document.createElement('id');
  //   const chartData = getData(eid);

  return <div className="App">{<Line data={data_c} options={options} />}</div>;
}
