import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Divider,
  ClickAwayListener,
  Button,
  Input,
} from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import history from 'utils/history';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Box from '@material-ui/core/Box';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { resetModulesStore } from 'actions/modules';
import { updateHomeStore } from 'actions/home';
import Test from './test';
import clsx from 'clsx';
import ReportsDialog from './ReportsDialog';
import Modal from 'components/Modal/Modal';
import _ from 'lodash';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import {
  SmartPharmaLogo,
  ExpandRight,
  HelpOutlined,
  UserProfileOutlined,
  LogoutOutlined,
  TawkChat,
  ThreeDots,
  OfficeBuilding,
} from 'icons/customIcons';
import SVGIcon from 'icons/SVGIcon';

import { COLORS } from 'constants/colors';

import apiRequest from 'utils/api';
import s from './Header.css';
import { store } from '../../store/index';

const state = store.getState();
const values = state?.themes;
const drawerWidthMini = 48;
const drawerWidthFull = 256;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: '0' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  appName: {
    fontWeight: 600,
    fontSize: '16px',
    margin: '0px 16px',
  },
  license: {
    display: 'block',
    margin: '10px 0px',
    padding: '10px',
    fontSize: '12px',
    color: '#fff',
  },
  searchIconSmallWrapper: {
    visibility: 'visible',
    opacity: '1',

    transitionDelay: '0s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIconSmall: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    backgroundColor: fade(theme.palette.common.white, 0.15),
  },
  search: {
    position: 'relative',
    borderRadius: '16px',
    backgroundColor: 'rgb(255,255,255,0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchIcon: {
    height: '100%',
    width: 'auto',
    padding: '0px 0px 0px 8px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: 'auto',
    height: '32px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    width: '100%',
    color: 'rgb(255,255,255,0.7)',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidthMini}px)`,
    marginLeft: `${drawerWidthMini}px`,
    boxShadow: 'none',
    height: '54px',
  },
  appBarShift: {
    marginLeft: drawerWidthFull,
    width: `calc(100% - ${drawerWidthFull}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: 'none',
    height: '54px',
  },
  menuButton: {
    margin: 0,
    color: '#ffffff',
    padding: '0px 4px 0px 4px',
  },
  headerIconButtons: {
    margin: '4px 4px 4px 12px',
    padding: '0px',
  },
  profileIconButton: {
    height: '100%',
    margin: '0',
    padding: '0 0 0 10px',
    width: '100%',
    fontFamily: 'Work Sans',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  profileInitialsContainer: {
    height: '33px',
    width: '33px',
    backgroundColor: '#07B67B',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1px',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthFull,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderRight: '0px',
  },
  drawerOpen: {
    width: drawerWidthFull,
    borderRight: '0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'visible',
  },
  drawerClose: {
    width: drawerWidthMini,
    borderRight: '0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: 'hidden',
    overflow: 'visible',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px',
    backgroundColor: values?.colours?.theme_primary ?? COLORS?.theme_primary,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  headerIcons: {
    fontSize: '21px',
  },
  listItemText: {
    color: '#7797BB',
    marginTop: '0px',
    marginBottom: '0px',
  },
  listItemTextPrimary: {
    color: 'rgb(255,255,255,0.5)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '500',
    fontSize: '13px',
    letterSpacing: '0.15px',
    fontFamily: 'Work Sans',
  },
  logoTextPrimary: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '0.8px',
    margin: '0px 0px 5px 0',
    color: '#FAFCFF',
  },
  show: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  spSubmenu: {
    display: 'none',
    position: 'absolute',
    top: '0',
    left: '256px',
    background: '#fff',
  },
  sidemenuList: {
    height: 'calc(100vh - 140px)',
  },
  primaryMenuItem: {
    margin: '10px 0px 12px',
    '& .MuiListItemIcon-root': {
      minWidth: 'fit-content',

      marginLeft: '-16px',
      width: '47px',
      justifyContent: 'center',
    },
    '& .MuiListItemIcon-root svg path,& .MuiListItemIcon-root svg rect': {
      fill: 'rgb(255,255,255,0.45)',
    },

    '&:hover': {
      '& .MuiListItemIcon-root svg path,& .MuiListItemIcon-root svg rect': {
        fill: '#fff',
      },
      '& .MuiListItemText-primary': {
        color: '#fff',
        fontWeight: 'bold',
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        transform: 'scale(1.02)',
      },
    },
  },
  searchResultItem: {
    backgroundColor: '#082c56',
    transform: `scale(1.0075)`,
    fontWeight: '500',
  },
  menuListItemIcon: {
    fontSize: '19px',
    color: '#567CAA',
  },
  menuListItemText: {
    color: '#6C8DB2',
  },
  submenuListItem: {
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '14px',
    borderBottom: '1px solid rgba(51, 90, 135, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  spMenuItemWrapper: {
    '& $spSubmenu': {
      // left: '250px',
      display: 'none',
      position: 'absolute',
      top: '-16px',
      left: '256px',
      borderRadius: '5px',
      // background: '#fff',
      border: 'solid 1px #fafcff',
      background: '#fafcff',
      /* background: #eef3f9; */
      boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
      minWidth: '300px',
      maxHeight: '350px',
      overflowY: 'auto',
    },

    '&:hover': {
      '& $spSubmenu': {
        display: 'block',
      },
    },
    '& $submenuCollapsed': {
      left: '48px',
    },
    '&:nth-child(n + 4)': {
      '& $spSubmenu': {
        top: 'auto',
        bottom: '0',
      },
    },
  },
  submenuCollapsed: {
    left: '48px',
  },

  submenuUnCollapsed: {
    display: 'none',
    position: 'absolute',
    top: '0',
    left: '256px',
    background: '#f0f8ff',
    border: 'solid 1px #fafcff',
  },

  show: {
    display: 'block',
  },

  hidden: {
    display: 'none',
  },
  expandIconContainer: {
    transition: '0.40s',
    minHeight: '24px',
    height: '24px',
    minWidth: '24px',
    backgroundColor: 'rgb(255,255,255,0.2)',
    borderRadius: '6px',
    marginTop: '16px',
    cursor: 'pointer',
  },
  collapseIconContainer: {
    transition: '0.40s',
    transform: 'rotate(180deg)',
    minHeight: '24px',
    minWidth: '24px',
    backgroundColor: '#335A87',
    borderRadius: '6px',
    marginTop: '16px',
    cursor: 'pointer',
  },
  expandBarItem: {
    padding: '0px',
    justifyContent: 'center',
  },
  collapseBarItem: {
    padding: '0px 16px 0px 0px',
    justifyContent: 'end',
  },
  expandIconDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    transition: '0.40s',
  },
  collapseIconDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    transition: '0.40s',
    transform: 'rotate(180deg)',
  },
  profileMenuListItemText: {
    display: 'inline',
    margin: '0 0 0 8px',
    letterSpacing: '0.1px',
    color: values?.colours?.theme_primary_text ?? COLORS?.theme_primary_text,
  },
  showD: {
    visibility: 'visible',
    opacity: '1',

    transitionDelay: '0s',
  },
  hiddenD: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s linear 0.33s, opacity 0.33s linear',
  },
  searchResults: {
    margin: '0',
    fontWeight: '400',
    '& > p': {
      color: '#5E83AE',
      marginBottom: '27px',
      fontSize: '14px',
      fontWeight: '500',
      padding: '0 14px 0 14px',
    },
    '& > div': {
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
    },
    '& > div > div': {
      padding: '10px 14px 10px 14px',
      cursor: 'pointer',
      wordWrap: 'break-word',
      whiteSpace: '-moz-pre-wrap !important',
      whiteSpace: '-pre-wrap',
      whiteSpace: '-o-pre-wrap',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      whiteSpace: '-webkit-pre-wrap',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      transition: 'transform 0.2s',
      '&:hover': {
        backgroundColor: '#082c56',
        transform: `scale(1.0075)`,
        fontWeight: '500',
      },
    },
  },
  licensingInfoLabel: {
    display: 'inline',
    margin: '0px',
    color: '#A6BDD8',
  },
  shortcutKeyContainer: {
    borderRadius: '6px',
    padding: '4px 8px',
    color: '#2072D3',
    backgroundColor: '#E4EFFC',
    fontWeight: '500',
    letterSpacing: '0.4px',
  },
  helpBotTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#003471',
    margin: '0',
  },
  helpBotText: {
    fontSize: '14px',
    lineHeight: '22px',
    margin: '0',
  },
  helpBotOptionItem: {
    padding: '0',
    height: '56px',
    borderTop: 'solid 1px #DDE8F5',
    '&:hover': {
      backgroundColor: '#f2f9f9',
      cursor: 'pointer',
    },
  },
  helpActiveOption: {
    padding: '0 16px',
    minWidth: '0',
    '& > svg > path': {
      stroke: '#2A8BFD',
    },
  },
  helpInactiveOption: {
    padding: '0 16px',
    minWidth: '0',
    '& > svg > path': {
      stroke: '#98A4AA',
    },
  },
  ulistHeadNew: {
    fontSize: '14px',
    margin: '24px 0 8px 0',
    whiteSpace: 'pre-wrap',
    lineHeight: '2',
  },
}));

export default function Header({
  title,
  screenShortcuts,
  helpWindow,
  warnNavigationAway = false,
  navigateAway = () => { },
  subMenuOpen,
  setSubMenuOpen = () => { },
}) {
  const dispatch = useDispatch();

  const invoiceSettings = useSelector((state) => state.settings);
  const permissions = useSelector((state) => state.permissions);
  const userDetails = useSelector((state) => state.user);
  const modules = useSelector((state) => state.modules);
  const settings = useSelector((state) => state.settings);
  const home = useSelector((state) => state.home);
  const releaseInfo = useSelector((state) => state.releaseInfo);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [searchAccess, setSearchAccess] = React.useState([]);
  const [searchResultsHighlight, setSearchResultsHighlight] = React.useState(0);
  const [showLess, setShowLess] = React.useState(false);
  const [renderModules, setRenderModules] = React.useState([]);
  const [firmDetails, setFirmDetails] = React.useState({
    firm_name: '',
    branch_name: '',
  });
  const [counter, setCounter] = React.useState(0);

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpBotOpen, setHelpBotOpen] = useState(false);
  const [helpTab, setHelpTab] = useState(0);

  const appSettingsVisible =
    permissions?.all != undefined &&
      permissions?.all?.filter((perm) => perm?.desc === 'settings')?.length !== 0
      ? true
      : false;

  const testRef = useRef();
  const tawkMessengerRef = useRef();

  React.useEffect(() => {
    setFirmDetails({
      firm_name:
        invoiceSettings?.firm_name?.length < 20
          ? invoiceSettings?.firm_name
          : invoiceSettings?.firm_name?.slice(0, 20).concat('...'),
      branch_name: invoiceSettings?.branch_name,
    });
  }, [invoiceSettings]);

  React.useEffect(() => {
    setSearchResultsHighlight(0);
    if (search?.length > 0) {
      let searchItems = [];
      modules?.modules?.forEach((menuItem) => {
        const submenuResults = menuItem?.submenu?.filter((submenuItem) => {
          const title = submenuItem?.title || '';
          return title.toLowerCase().startsWith(search.toLowerCase());
        });

        searchItems.push(...submenuResults);
      });
      const max = searchItems.length < 9 ? searchItems.length : 9;
      let slicedSearch = searchItems.slice(0, max);
      setSearchAccess([...slicedSearch]);
    } else {
      setSearchAccess([]);
    }
  }, [search]);

  React.useEffect(() => {
    if (window) {
      const currentHref = window.location.href;
      if (currentHref.indexOf('/privacy') > -1) {
        setShowLess(true);
      }
    }
  }, []);

  React.useEffect(() => {
    if (modules?.modules) {
      setRenderModules(modules?.modules);
    }
  }, [modules]);

  React.useEffect(() => {
    if (!open) {
      setSearch('');
    }
  }, [open]);

  const homeClick = () => {
    warnNavigationAway ? navigateAway('/home') : history.push('/home');
    setSearch('');
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  function userLogoutHandler() {
    userLogout();
    setProfileMenuOpen(false);
  }

  const keyMap = {
    back_hotkey: 'Control+Backspace',
    homescreen_hotkey: ['Home'],
    reports_dialog_hotkey: ['Alt+R', 'Alt+r'],
    calculator_hotkey: ['Alt+C', 'Alt+c'],
    app_search_hotkey: ['Control+/'],
  };
  const handlers = {
    back_hotkey: () => {
      const currentHref = window.location.href;
      if (currentHref.indexOf('/home') === -1) {
        history.goBack();
      }
    },
    homescreen_hotkey: () => {
      history.replace('/home', 'urlhistory');
      setSearch('');
    },
    reports_dialog_hotkey: () => {
      dispatch(
        updateHomeStore({
          reportsDialogOpen: true,
        }),
      );
    },
    calculator_hotkey: () => {
      window.open('Calculator:///');
    },
    app_search_hotkey: () => {
      handleDrawerToggle();
    },
  };
  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: (event) => {
      const { target } = event;

      if (target && target.tagName) {
        // const tagName = target.tagName.toLowerCase();

        return target.isContentEditable;
      }
      return false;
    },
  });

  const renderSubmenuItem = (submenuItem) => {
    return (
      <ListItem
        classes={{ root: classes.submenuListItem }}
        button
        key={submenuItem.title}
        onClick={() => navCloseNRoute(submenuItem.route)}
      >
        <ListItemText className="sp-submenu" primary={submenuItem.title} />
      </ListItem>
    );
  };

  const navCloseNRoute = (route) => {
    setOpen(false);
    warnNavigationAway ? navigateAway(route) : history.push(route);
  };

  const userLogout = async () => {
    const answer = window.confirm('Are you sure you want to logout ?');
    if (answer) {
      await apiRequest(`/auth/logout`, null, 'GET').then((response) => {
        clearSession(response);
      });
    }
  };

  function clearSession(logout) {
    localStorage.removeItem('username');
    localStorage.removeItem('tenent');
    localStorage.removeItem('userId');
    localStorage.removeItem('authorization');
    dispatch({ type: 'USER_LOGOUT' });
    dispatch(resetModulesStore());
    history.push('/login');
  }

  function handleSearchArrowNavigation(event) {
    if (searchAccess.length > 0)
      if (event.key == 'ArrowUp') {
        event.preventDefault();
        let current = searchResultsHighlight;
        if (current == 0) setSearchResultsHighlight(searchAccess.length - 1);
        else setSearchResultsHighlight(current - 1);
      } else if (event.key == 'ArrowDown') {
        event.preventDefault();
        let current = searchResultsHighlight;
        if (current == searchAccess.length - 1) setSearchResultsHighlight(0);
        else setSearchResultsHighlight(current + 1);
      } else if (event.key == 'Enter') {
        setOpen(false);
        warnNavigationAway
          ? navigateAway(searchAccess[searchResultsHighlight]?.route)
          : history.push(searchAccess[searchResultsHighlight]?.route);
      }
  }

  const searchResults = (searchResults) => {
    return (
      <div className={classes.searchResults}>
        <p>Search Results</p>
        <div>
          {searchResults.length > 0 ? (
            searchResults.map((menuItem, index) => (
              <div
                key={menuItem.title}
                onClick={() => {
                  setOpen(false);
                  warnNavigationAway
                    ? navigateAway(menuItem.route)
                    : history.push(menuItem.route);
                }}
                className={
                  index == searchResultsHighlight
                    ? classes.searchResultItem
                    : {}
                }
              >
                {menuItem.title}
              </div>
            ))
          ) : (
            <p style={{ padding: '0 14px 0 14px' }}>No results found :(</p>
          )}
        </div>
      </div>
    );
  };

  const generateMenu = () => {
    return renderModules?.map((menuItem) => {
      if (menuItem?.submenu.length > 0) {
        return (
          <div
            key={menuItem?.title}
            className={classes.spMenuItemWrapper}
            style={{ position: 'relative' }}
            onMouseEnter={() => {
              if (menuItem?.title != 'Reports') setSubMenuOpen(true);
            }}
            onMouseLeave={() => {
              setSubMenuOpen(false);
            }}
          >
            <ListItem
              button
              className={classes.primaryMenuItem}
              onClick={(ev) => {
                if (menuItem?.title === 'Reports') {
                  dispatch(
                    updateHomeStore({
                      reportsDialogOpen: true,
                    }),
                  );
                }
              }}
            >
              <ListItemIcon>
                <SVGIcon name={menuItem?.icon} />
              </ListItemIcon>

              <ListItemText
                classes={{
                  root: clsx(classes.listItemText, {
                    [classes.show]: open,
                    [classes.hidden]: !open,
                  }),
                  primary: clsx(classes.listItemTextPrimary),
                }}
                primary={menuItem.title}
              />
            </ListItem>
            {menuItem?.title != 'Reports' ? (
              <div
                className={clsx(classes.spSubmenu, {
                  [classes.submenuCollapsed]: !open,
                  [classes.submenuUnCollapsed]: open,
                })}
              >
                <List>
                  {menuItem.submenu?.map((submenuItem) =>
                    renderSubmenuItem(submenuItem),
                  )}
                </List>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      } else {
        return <></>;
      }
    });
  };

  const sideNavMenu = () => {
    return (
      <div
        style={{
          height: '100%',
          backgroundColor:
            values?.colours?.theme_primary ?? COLORS?.theme_primary,
          position: 'relative',
        }}
      >
        <List
          classes={{
            root: clsx(classes.sidemenuList),
          }}
        >
          {search?.length > 0
            ? searchAccess?.length
              ? searchResults(searchAccess)
              : searchResults([])
            : generateMenu()}
        </List>

        <div
          style={{
            position: 'absolute',
            bottom: 40,
            height: '64px',
            width: '100%',
            backgroundColor:
              values?.colours?.theme_primary ?? COLORS?.theme_primary,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              backgroundColor:
                values?.colours?.theme_primary ?? COLORS?.theme_primary,
              padding: '0px 4px',
            }}
          >
            <Divider style={{ backgroundColor: 'rgb(255, 255, 255,0.25)' }} />
          </div>
          <List>
            <ListItem
              button
              key="Help"
              className={classes.primaryMenuItem}
              style={{ paddingLeft: '14px', margin: 0 }}
              onClick={() => {
                try {
                  setTimeout(() => {
                    tawkMessengerRef.current.toggle();
                  }, 10);
                } catch (error) { }
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '38px',
                  marginRight: '2px',
                  position: 'relative',
                }}
              >
                <TawkChat />
                <span
                  style={{
                    backgroundColor: '#C4F049',
                    height: '8px',
                    width: '8px',
                    position: 'absolute',
                    borderRadius: '50%',
                    right: '8px',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: clsx(classes.listItemText, {
                    [classes.show]: open,
                    [classes.hidden]: !open,
                  }),
                  primary: clsx(classes.listItemTextPrimary),
                }}
                primary="Chat Support"
              />
              <TawkMessengerReact
                propertyId="6015d99ba9a34e36b9722aa9"
                widgetId="1etalk22n"
                ref={tawkMessengerRef}
              />
            </ListItem>
          </List>
        </div>
      </div>
    );
  };

  const profileMenu = () => {
    return (
      <div
        className="profileDropDown"
        style={{
          visibility: profileMenuOpen ? 'visible' : 'hidden',
        }}
      >
        <Typography className={classes.license} variant="inherit" noWrap>
          <p className={classes.licensingInfoLabel}>Licensed To:&nbsp;</p>
          {firmDetails?.firm_name}
          <br />
          <p className={classes.licensingInfoLabel}>Branch:&nbsp;</p>
          {firmDetails?.branch_name}
          {/* <br />
          <p className={classes.licensingInfoLabel}>Logged As:&nbsp;</p>
          {userDetails.first_name}&nbsp;{userDetails.last_name} */}
        </Typography>
        <ul>
          <li
            onClick={() => {
              setProfileMenuOpen(false);
              warnNavigationAway
                ? navigateAway('/profile')
                : history.push('/profile');
            }}
          >
            <UserProfileOutlined />
            <p className={classes.profileMenuListItemText}>My Profile</p>
          </li>
          <li
            onClick={(e) => {
              warnNavigationAway
                ? navigateAway(null, userLogoutHandler)
                : userLogoutHandler();
            }}
          >
            <LogoutOutlined />
            <p className={classes.profileMenuListItemText}>Logout</p>
          </li>
        </ul>
      </div>
    );
  };

  const handleHelpClick = () => {
    if (Object.keys(screenShortcuts).length > 0) {
      handleHelpOpen();
    } else if (Object.keys(helpWindow).length > 0) {
      handleHelpBotOpen();
    }
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
  };
  const handleHelpOpen = () => {
    setHelpOpen(true);
  };
  React.useEffect(() => {
    setCounter(0);
  }, [title]);
  React.useEffect(() => {
    if (counter == 6) testRef.current.open();
  }, [counter]);
  const handleHelpBotClose = () => {
    setHelpBotOpen(false);
    setTimeout(() => {
      setHelpTab(0);
    }, 200);
  };
  const handleHelpBotOpen = () => {
    setHelpBotOpen(true);
  };
  const handleModalBotBack = () => {
    if (helpTab != 0) setHelpTab(0);
  };

  const renderShortcutsContent = () => {
    if (Object.keys(screenShortcuts).length > 0)
      return (
        <>
          {screenShortcuts.map((row) => {
            const myArray = row.shortcut.split('+');
            return (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <p style={{ margin: '12px 0' }}>{row.function}</p>
                </Grid>
                <Grid item>
                  <span className={classes.shortcutKeyContainer}>
                    {myArray[0].trim()}
                  </span>
                  {myArray[1] && (
                    <>
                      <p
                        style={{
                          margin: '0px',
                          display: 'inline',
                          padding: '0 4px',
                          color: '#2981E9',
                        }}
                      >
                        {'+'}
                      </p>
                      <span className={classes.shortcutKeyContainer}>
                        {myArray[1].trim()}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </>
      );
  };

  const handleHelpOptionSelected = (action) => {
    switch (action) {
      case 'show_shortcuts':
        setHelpTab(1);
        break;
      case 'show_tutorials':
        if (helpWindow?.navattic_key)
          openNavatticTutorial(helpWindow?.navattic_key);
        else alert('Tutorial Unavailable for this module !');
        break;
      case 'whats_new':
        setHelpTab(3);
        break;
      default:
        break;
    }
  };

  function openNavatticTutorial(id) {
    const winHtml = `<!DOCTYPE html>
    <html style="height:100%">
        <head>
        <meta charset="utf-8">
            <title>Tutorial</title>
        </head>
        <body style="height:100%;margin:0">
        <iframe src="https://risurge-technologies.navattic.com/${id}" style="width:100%; height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>
        </body>
    </html>`;
    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: 'text/html' }),
    );
    const win = window.open(
      winUrl,
      'win',
      `width="+screen.availWidth+",height="+screen.availHeight"`,
    );
  }

  const helpModalContent = {
    header: 'Shortcut Keys',
    body: (
      <>
        <div className={s.tabmenu}>{renderShortcutsContent()}</div>
      </>
    ),
  };

  const helpBotModalContent = {
    body: (
      <>
        {/* HOME */}
        <TabPanel value={helpTab} index={0}>
          <Grid container style={{ width: '100%', height: '420px' }}>
            <Grid
              item
              style={{
                padding: '24px 48px 36px 64px',
                width: '38%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src="/images/mascot.svg" alt="mascot" />
            </Grid>
            <Grid
              item
              style={{
                height: '100%',
                width: '62%',
                padding: '24px 32px 36px 0',
              }}
            >
              <Grid
                container
                flexDirection="column"
                style={{ paddingRight: '16px', height: '100%' }}
              >
                <p className={classes.helpBotTitle}>Hey there !</p>
                <p className={classes.helpBotText} style={{ margin: '16px 0' }}>
                  I'm SmartBot and I'm here to make your journey on the
                  application easier.
                </p>
                <p
                  className={classes.helpBotText}
                  style={{ marginBottom: '16px' }}
                >
                  {helpWindow?.subText}
                </p>
                <List style={{ width: '100%' }}>
                  {helpWindow?.options?.map((option, option_index) => {
                    return (
                      <ListItem
                        className={classes.helpBotOptionItem}
                        onClick={(event) => {
                          if (option.active)
                            handleHelpOptionSelected(option?.action);
                        }}
                      >
                        <Grid
                          container
                          style={{ justifyContent: 'space-between' }}
                        >
                          <Grid item style={{ display: 'flex' }}>
                            <ListItemIcon
                              className={clsx({
                                [classes.helpActiveOption]: option?.active,
                                [classes.helpInactiveOption]: !option?.active,
                              })}
                            >
                              <SVGIcon name={option?.icon} />
                            </ListItemIcon>
                            <p
                              className={classes.helpBotText}
                              style={{
                                fontWeight: '500',
                                color: option?.active ? '#2A8BFD' : '#98A4AA',
                              }}
                            >
                              {option?.text}
                            </p>
                          </Grid>
                          <Grid
                            item
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {option_index == 2 && (
                              <p style={{ margin: '0 16px', fontSize: '13px' }}>
                                (v{releaseInfo?.version})
                              </p>
                            )}
                            <ListItemIcon
                              className={clsx({
                                [classes.helpActiveOption]: option?.active,
                                [classes.helpInactiveOption]: !option?.active,
                              })}
                              style={{ padding: '0' }}
                            >
                              <SVGIcon name="RightArrow" />
                            </ListItemIcon>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        {/* SHORTCUTS */}
        <TabPanel value={helpTab} index={1}>
          <div
            style={{
              width: 'calc(100% - 80px)',
              height: '388px',
              padding: '16px 40px',
            }}
          >
            <Grid container style={{ width: '100%' }}>
              {helpWindow?.shortcuts?.map((row, shortcut_index) => {
                const myArray = row.shortcut.split('+');
                return (
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{
                      height: 'fit-content',
                      padding:
                        shortcut_index % 2 == 0
                          ? '0 20px 8px 0'
                          : '0 0 8px 20px',
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <p style={{ margin: '10px 0' }}>{row.function}</p>
                      </Grid>
                      <Grid item>
                        <span className={classes.shortcutKeyContainer}>
                          {myArray[0].trim()}
                        </span>
                        {myArray[1] && (
                          <>
                            <p
                              style={{
                                margin: '0px',
                                display: 'inline',
                                padding: '0 4px',
                                color: '#2981E9',
                              }}
                            >
                              {'+'}
                            </p>
                            <span className={classes.shortcutKeyContainer}>
                              {myArray[1].trim()}
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </TabPanel>
        {/* TUTORIALS */}
        <TabPanel value={helpTab} index={2}>
          <Grid
            container
            style={{
              width: '100%',
              height: '420px',
              padding: '16px 40px 32px',
            }}
          ></Grid>
        </TabPanel>
        {/* WHATS NEW */}
        <TabPanel value={helpTab} index={3}>
          <Grid
            container
            style={{
              width: '100%',
              height: '420px',
              padding: '0 40px',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              marginBottom: '24px',
              overflow: 'scroll',
            }}
          >
            <>
              <Grid item>
                <p className={classes.ulistHeadNew}>
                  {releaseInfo?.release_notes}
                </p>
              </Grid>
            </>
          </Grid>
        </TabPanel>
      </>
    ),
  };

  return (
    <div className={s.root}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
        <div style={{ height: '100%' }}>
          <AppBar
            position="static"
            style={{
              background: '#f4faff',
              color:
                values?.colours?.theme_primary_text ??
                COLORS?.theme_primary_text,
              padding: '6px 16px',
              marginLeft: permissions?.branch_type == 3 ? '0px' : '',
              width: permissions?.branch_type == 3 ? '100%' : '',
            }}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar style={{ padding: '0px', minHeight: '42px' }}>
              <Grid container>
                <Grid item md={7} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      style={{
                        background: 'white',
                        borderRadius: '50%',
                        fontWeight: 'bold',
                        padding: '6px',
                        color:
                          values?.colours?.theme_primary ??
                          COLORS?.theme_primary,
                        boxShadow: '0px 2px 6px rgb(41 129 233 / 20%)',
                      }}
                      color="inherit"
                      onClick={() => {
                        const currentHref = window.location.href;
                        if (currentHref.indexOf('/home') == -1) {
                          history.goBack();
                        }
                      }}
                    >
                      <ArrowBackSharpIcon className={classes.headerIcons} />
                    </IconButton>
                    <div>
                      <p className={classes.appName}>{title ?? ''}</p>
                    </div>
                  </Grid>
                </Grid>

                <Grid item md={5} xs={12}>
                  {!showLess && (
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      style={{ height: '100%' }}
                    >
                      <Grid
                        container
                        style={{
                          height: '100%',
                          width: 'auto',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <Grid
                          item
                          style={{
                            height: '100%',
                            display: 'flex',
                            fill: values?.colours?.theme_primary ?? '#397BC8',
                          }}
                        >
                          <OfficeBuilding />
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          style={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%',
                            padding: '0 10px',
                            flexGrow: 1,
                          }}
                        >
                          <Grid
                            item
                            style={{
                              height: '16px',
                              fontSize: '12px',
                              fontWeight: '600',
                              width: '100%',
                              display: 'flex',
                              textAlign: 'left',
                            }}
                          >
                            {(settings?.branch_name ?? '').length > 18
                              ? (settings?.branch_name ?? '')
                                .slice(0, 17)
                                .concat('..')
                              : settings?.branch_name ?? ''}
                          </Grid>
                          <Grid
                            item
                            style={{
                              fontSize: '11px',
                              fontWeight: '200',
                              color: '#7d94a8',
                              textTransform: 'capitalize',
                            }}
                          >
                            {(settings?.city ?? '').length > 19
                              ? (settings?.city ?? '')
                                .slice(0, 17)
                                .toLowerCase()
                                .concat('..')
                              : (settings?.city ?? '').toLowerCase()}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Divider
                            vertical
                            style={{
                              height: 'calc(100% - 12px)',
                              width: '1.4px',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item style={{ height: '100%', width: '152px' }}>
                        <ClickAwayListener
                          onClickAway={() => {
                            setProfileMenuOpen(false);
                          }}
                        >
                          <div style={{ height: '100%', width: '100%' }}>
                            <Button
                              className={classes.profileIconButton}
                              color="inherit"
                              onClick={() =>
                                setProfileMenuOpen(!profileMenuOpen)
                              }
                            >
                              <Grid container>
                                <Grid
                                  container
                                  item
                                  md={10}
                                  direction="column"
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <Grid
                                    item
                                    style={{
                                      height: '16px',
                                      fontSize: '12px',
                                      fontWeight: '600',
                                      width: '100%',
                                      paddingRight: '16px',
                                      display: 'flex',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {(userDetails?.first_name ?? '')
                                      .concat(' ')
                                      .concat(
                                        (userDetails?.last_name ?? '').slice(
                                          0,
                                          1,
                                        ),
                                      ).length > 14
                                      ? (userDetails?.first_name ?? '')
                                        .concat(' ')
                                        .concat(
                                          (
                                            userDetails?.last_name ?? ''
                                          ).slice(0, 1),
                                        )
                                        .slice(0, 12)
                                        .concat('..')
                                      : (userDetails?.first_name ?? '')
                                        .concat(' ')
                                        .concat(
                                          (
                                            userDetails?.last_name ?? ''
                                          ).slice(0, 1),
                                        )}
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      fontSize: '11px',
                                      fontWeight: '200',
                                      color: '#7d94a8',
                                    }}
                                  >
                                    {(userDetails?.role_name ?? 'Role').length >
                                      14
                                      ? (userDetails?.role_name ?? 'Role')
                                        .slice(0, 12)
                                        .concat('..')
                                      : userDetails?.role_name ?? 'Role'}
                                  </Grid>
                                </Grid>
                                <Grid container item md={1}>
                                  <ThreeDots />
                                </Grid>
                                <Grid
                                  container
                                  item
                                  md={1}
                                  style={{ justifyContent: 'flex-end' }}
                                >
                                  <Divider
                                    vertical
                                    style={{
                                      height: 'calc(100% - 8px)',
                                      width: '1px',
                                      margin: '4px 0',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Button>

                            {profileMenu()}
                          </div>
                        </ClickAwayListener>
                      </Grid>
                      <IconButton
                        className={classes.headerIconButtons}
                        style={{
                          fill:
                            values?.colours?.theme_primary ??
                            COLORS?.theme_primary,
                        }}
                        onClick={handleHelpClick}
                      >
                        <HelpOutlined />
                      </IconButton>

                      {appSettingsVisible == true && (
                        <IconButton
                          className={classes.headerIconButtons}
                          color="inherit"
                          style={{
                            color:
                              values?.colours?.theme_primary ??
                              COLORS?.theme_primary,
                          }}
                          onClick={() => {
                            // CHECK FOR PERMISSION
                            warnNavigationAway
                              ? navigateAway('/settings')
                              : history.push('/settings');
                          }}
                        >
                          <SettingsIcon
                            className={classes.headerIcons}
                            style={{ fontSize: '22px' }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Modal
            content={helpModalContent}
            open={helpOpen}
            modalClose={handleHelpClose}
            size="xs"
          ></Modal>

          <Modal
            content={helpBotModalContent}
            open={helpBotOpen}
            modalClose={handleHelpBotClose}
            modalBack={handleModalBotBack}
            size="md"
            backArrow={true}
          />
          <Test ref={testRef} />
        </div>
        {
          /* SideNav Bar render blocked in HQ Dashboard */
          permissions?.branch_type !== 3 && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar}>
                  <ListItem>
                    <ListItemAvatar
                      button
                      onClick={homeClick}
                      style={{
                        cursor: 'pointer',
                        marginLeft: values?.images?.logo_nav_bar
                          ? '-10px'
                          : '-18px',
                      }}
                    >
                      {values?.images?.logo_nav_bar ? (
                        <img src={values?.images?.logo_nav_bar} alt="logo" />
                      ) : (
                        <SmartPharmaLogo />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      onClick={(ev) => {
                        setCounter(counter + 1);
                      }}
                      style={{
                        overflow: 'hidden',
                        color: COLORS.white,
                      }}
                      classes={{
                        root: clsx(classes.listItemText, {
                          [classes.show]: open,
                          [classes.hidden]: !open,
                        }),
                        primary: clsx(classes.logoTextPrimary),
                      }}
                      primary={values?.other?.app_name ?? 'SmartPharma360'}
                    />
                  </ListItem>
                </div>
                <div className={classes.toolbar}>
                  <ListItem
                    button
                    disableRipple
                    className={classes.searchListItem}
                  >
                    <div
                      onClick={() => {
                        handleDrawerToggle();
                      }}
                      style={{ marginLeft: '-6px' }}
                      className={clsx({
                        [classes.searchIconSmallWrapper]: !open,
                        [classes.hiddenD]: open,
                      })}
                    >
                      <div className={classes.searchIconSmall}>
                        <SearchIcon
                          style={{
                            fontSize: '1.35rem',
                            color: 'rgb(255,255,255,0.7)',
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        overflow: 'hidden',
                        marginLeft: '-29px',
                        width: '234px',
                      }}
                      className={clsx(classes.listItemText, {
                        [classes.showD]: open,
                        [classes.hiddenD]: !open,
                      })}
                    >
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon
                            style={{
                              fontSize: '1.35rem',
                              color: 'rgb(255,255,255,0.6)',
                            }}
                          />
                        </div>
                        <InputBase
                          autoFocus={true}
                          inputRef={(input) => input && input.focus()}
                          placeholder="Search Home Cards"
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          contentEditable
                          suppressContentEditableWarning
                          value={search ?? ''}
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                          }}
                          onKeyDown={handleSearchArrowNavigation}
                        />
                        <span
                          style={{
                            padding: '4px 6px',
                            backgroundColor: 'rgb(255, 255, 255,0.4)',
                            borderRadius: '10px',
                            fontSize: '11px',
                            color:
                              values?.colours?.theme_primary ??
                              COLORS?.theme_primary,
                            letterSpacing: '0.5px',
                            marginRight: '6px',
                            fontWeight: '500',
                          }}
                        >
                          Ctrl+/
                        </span>
                      </div>
                    </div>
                  </ListItem>
                </div>
                <div
                  style={{
                    backgroundColor:
                      values?.colours?.theme_primary ?? COLORS?.theme_primary,
                    padding: '0px 4px',
                  }}
                >
                  <Divider
                    style={{ backgroundColor: 'rgb(255, 255, 255,0.25)' }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor:
                      values?.colours?.theme_primary ?? COLORS?.theme_primary,
                  }}
                >
                  <ListItem
                    className={clsx({
                      [classes.expandBarItem]: !open,
                      [classes.collapseBarItem]: open,
                    })}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon className={classes.expandIconContainer}>
                      <div
                        className={clsx({
                          [classes.expandIconDiv]: !open,
                          [classes.collapseIconDiv]: open,
                        })}
                      >
                        <ExpandRight />
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </div>
                {sideNavMenu()}
              </Drawer>
            </ClickAwayListener>
          )
        }
      </GlobalHotKeys>

      <ReportsDialog
        open={home?.reportsDialogOpen}
        warnNavigationAway={warnNavigationAway}
        navigateAway={navigateAway}
        setOpen={(val) => {
          dispatch(
            updateHomeStore({
              reportsDialogOpen: val,
            }),
          );
        }}
      />
    </div>
  );
}
