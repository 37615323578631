import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Grid,
  Badge,
  Menu,
  MenuList,
  MenuItem,
  Card,
  CardContent,
  ListItemText,
  Box,
  Switch,
  Dialog,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { COLORS } from 'constants/colors';
import { numberFormat } from 'constants/number';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { GlobalHotKeys, configure } from 'react-hotkeys';
import history from 'utils/history';
import s from './Home.css';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updatePermissionsStore } from 'actions/permissions';
import { updateHomeStore } from 'actions/home';
import apiRequest from 'utils/api';
import { useSnackbar } from 'notistack';
import AreaChart from '../../components/Charts/AreaChart';
import DoughnutChart from '../../components/Charts/DoughnutChart';
import DropdownButton from '../../components/Buttons/DropdownButton';
import moment from 'moment';
import { percentCalc } from '../../utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import {
  BankBalance,
  CashOnHand,
  PaymentsOutlined,
  SupplierPDC,
  PayablesOutlined,
  RefreshFilled,
  IncreaseArrow,
  DecreaseArrow,
  ToggleDashboard,
  ToggleCards,
  Collapse,
} from 'icons/customIcons';
import HqDashHome from './HqDashHome';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { updateModulesStore } from 'actions/modules';

const inventorySectionTabs = {
  inventory_data: [
    { key: 'expiry', label: 'Expired Stock', value: '0.00' },
    { key: 'short_expiry', label: 'Short-Expiry (3M)', value: '0.00' },
    { key: 'non_moving', label: 'Non-Moving (45D)', value: '0.00' },
    { key: 'low_stock', label: 'Low Stock', value: '0.00' },
    { key: 'high_moving', label: 'High Moving', value: '0.00' },
    { key: 'out_of_stock', label: 'Out of Stock', value: '0.00' },
  ],
  closing_data: [{ key: 'closing', label: 'Closing Stock', value: '0.0' }],
};

//-----STYLED COMPONENTS-----------------------------------------------
const StyledMenu = withStyles({
  paper: {
    outline: '1px solid #CAD8DF',
    width: '115px',
  },
  list: {
    padding: '0px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    {...props}
    style={{
      top: '78px',
      left: '50px',
    }}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    width: '100%',
    padding: '4px 8px 4px 8px',
    '& .MuiListItemText-primary': {
      color: COLORS.cta_blue,
      fontSize: '12px',
    },
    '&:focus': {
      '&:hover': {
        '& .MuiListItemText-primary': {
          color: COLORS.cta_blue,
          fontSize: '12px',
        },
      },
      '& .MuiListItemText-primary': {
        color: COLORS.cta_blue,
        fontSize: '12px',
      },
    },
    '&:hover': {
      backgroundColor: '#EAF0F3',
      '& .MuiListItemText-primary': {
        color: COLORS.cta_blue,
        fontSize: '12px',
      },
    },
  },
}))(MenuItem);

const CustomSwitch = withStyles({
  root: {
    margin: '0px 0px 0px 10px',
    height: '36px',
    width: '66px',
    padding: '0px',
  },
  switchBase: {
    padding: '2.5px 3px',
    color: COLORS.cta_blue,
    '&$unchecked + $track': {
      backgroundColor: '#fff',
    },
    '&$checked': {
      transform: 'translateX(29px)',
      color: COLORS.cta_blue,
      '& + $track': {
        backgroundColor: '#fff',
        border: '1px solid #CAD8DF',
        opacity: 1,
      },
    },
  },
  checked: {},
  track: {
    borderRadius: '6px',
    backgroundColor: '#fff',
    border: '1px solid #CAD8DF',
    height: 'calc(100% - 2px)',
    opacity: 1,
  },
  thumb: {
    width: '30px',
    height: '30px',
    borderRadius: '6px',
    boxShadow: 'none',
  },
})(Switch);

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
    '& .MuiCollapse-container.MuiCollapse-entered': {
      height: 'calc(100% - 36px) !important',
      '& .MuiCollapse-wrapper': {
        height: '100%',
      },
      '& .MuiCollapse-wrapperInner': {
        height: '100%',
        '& > div': {
          height: '100%',
          fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        },
      },
    },
    '& .MuiButtonBase-root.MuiAccordionSummary-root': {
      padding: '0 16px 0 0',
      '& .Mui-expanded': {
        minHeight: '48px',
        '& .MuiAccordionSummary-content.Mui-expanded > p': {
          fontFamily: 'Inter',
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
      },
      '& .MuiAccordionSummary-content > p': {
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: '8px',
      },
    },
  },
  expanded: {},
})(MuiAccordion);

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const StyledReportMenuItem = withStyles((theme) => ({
  root: {
    padding: '16px',
    borderRadius: '0px',
    fontSize: '14px',
    fontWeight: '400',
    '&.Mui-selected': {
      backgroundColor: '#c0d5ee !important',
      color: '#003471',
      fontWeight: '500',
    },
    '&.MuiListItem-root.Mui-selected,.Mui-selected:hover': {
      backgroundColor: 'none',
    },
  },
}))(MenuItem);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '12px 16px',
  },
  closeButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    color: '#222222',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, searchInput, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Grid item>
          <p style={{ margin: '0', fontSize: '15px', fontWeight: '500' }}>
            {children}
          </p>
        </Grid>
        <Grid item>
          {searchInput}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    height: '640px',
  },
}))(MuiDialogContent);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ width: 'calc(100% - 16px)', padding: '4px 16px 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Home({}) {
  useStyles(s);

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const timePeriods = ['Today', 'This Week', 'This Month'];
  const defaultChartData = {
    labels: ['9 AM', '11 AM', '1 PM', '3 PM'],
    presentData: [0],
    pastData: [0],
  };

  const tableColumns = [
    { id: 'product_code', label: 'Product Code', type: 'label', minWidth: 50 },
    { id: 'product_name', label: 'Name', type: 'label', minWidth: 50 },
    { id: 'company_code', label: 'Company Code', type: 'label', minWidth: 60 },
    { id: 'company_name', label: 'Company Name', minWidth: 50, type: 'label' },
    {
      id: 'available_quantity',
      label: 'Avl Quantity',
      minWidth: 50,
      align: 'right',
      type: 'label',
    },
    {
      id: 'value',
      label: 'Value',
      minWidth: 80,
      type: 'value',
      align: 'right',
    },
  ];

  const [selectedReportTab, setSelectedReportTab] = React.useState(0);

  const permissions = useSelector((state) => state.permissions);
  const userDetails = useSelector((state) => state.user);
  const home = useSelector((state) => state.home);
  const modules = useSelector((state) => state.modules);

  let isSuperAdmin;

  if (userDetails?.is_superuser != undefined)
    isSuperAdmin = userDetails?.is_superuser;

  const branchType = permissions?.branch_type;

  const [inventoryTableTabs, setInventoryTableTabs] =
    React.useState(inventorySectionTabs);
  const [inventoryActive, setInventoryActive] = React.useState(
    inventorySectionTabs?.inventory_data[0].key,
  );
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [currentPeriod, setCurrentPeriod] = React.useState(0);
  const [lastPeriodLabel, setLastPeriodLabel] = React.useState('Yesterday');
  const [lastUpdated, setLastUpdated] = React.useState(
    home?.lastUpdated || moment().format('MMM DD hh:mm:ss'),
  );

  const [salesChart, setSalesChart] = React.useState(defaultChartData);
  const [purchaseChart, setPurchaseChart] = React.useState(defaultChartData);
  const [customersChart, setCustomersChart] = React.useState(defaultChartData);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState(tableColumns);
  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);
  const [allModules, setAllModules] = React.useState([]);
  const [allReports, setAllReports] = React.useState([]);
  const [reportsMenu, setReportsMenu] = React.useState();
  const [salesCashFlows, setSalesCashFlows] = React.useState();
  const [reportSearch, setReportSearch] = React.useState('');
  const [favourites, setFavourites] = React.useState([]);
  const [favoritesExpanded, setFavoritesExpanded] = React.useState(true);
  const [sectionCollapseState, setSectionCollapseState] = React.useState({
    text: 'Collapse All',
    collapse: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dashboardAccess, setDashboardAccess] = React.useState(false);

  React.useEffect(() => {
    let dashboardAccessCheck = permissions?.all?.find(
      (perm) =>
        perm?.desc === 'admin_user' ||
        (perm?.desc === 'dashboard' && perm?.access == true),
    );

    if (dashboardAccessCheck) {
      setDashboardAccess(dashboardAccessCheck?.access != 0 ? true : false);
    }
  }, [permissions]);

  React.useEffect(() => {
    if (modules?.modules?.length > 0) {
      let all_modules = [...modules?.modules];
      let reports_index = all_modules.findIndex(
        (obj) => obj.title == 'Reports',
      );
      if (reports_index != -1) {
        let all_reports = [];
        modules?.reports?.forEach((group) => {
          if (Object.keys(group).includes('reports'))
            all_reports.splice(all_reports?.length, 0, ...group?.reports);
          if (Object.keys(group).includes('sub_group'))
            group?.sub_group?.forEach((subGroup) => {
              all_reports.splice(all_reports?.length, 0, ...subGroup?.reports);
            });
        });
        let reports_obj = reports_index != -1 ? all_modules[reports_index] : {};
        all_modules[reports_index] = {
          ...reports_obj,
          submenu: all_reports,
        };

        all_modules = all_modules
          ?.filter((mod) => mod?.submenu?.length > 0)
          ?.map((mod) => ({
            ...mod,
            expanded: true,
          }));

        setAllModules(all_modules);
        setAllReports(all_reports);
      }
    }
  }, [modules?.modules]);
  React.useEffect(() => {
    if (modules?.favourites?.length > 0)
      setFavourites([...modules?.favourites]);
  }, [modules?.favourites]);
  React.useEffect(() => {
    if (modules?.reports?.length > 0) setReportsMenu([...modules?.reports]);
  }, [modules?.reports]);
  React.useEffect(() => {
    if (!home?.lastUpdated) {
      dispatch(
        updateHomeStore({
          homeAnalytics: [],
          salesCashFlows: [],
        }),
      );
      setTimeout(() => {
        componentMountCalls();
        fetchSalesCashFlow(currentPeriod);
        fetchDashboardAnalytics(currentPeriod);
      }, 100);
    }
  }, [home?.lastUpdated]);

  React.useEffect(() => {
    componentMountDashboardRefresh();
  }, [currentPeriod]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function componentMountCalls() {
    fetchLatestPermissions();

    if (home?.lastUpdated) {
      setLastUpdated(home?.lastUpdated);
    } else {
      dispatch(
        updateHomeStore({
          lastUpdated: moment().format('MMM DD hh:mm:ss'),
        }),
      );
      setLastUpdated(moment().format('MMM DD hh:mm:ss'));
    }
    if (home?.switchView == undefined) {
      dispatch(
        updateHomeStore({
          switchView: true,
        }),
      );
    }

    // tawkTo(tawkToPropertyId);
  }

  function componentMountDashboardRefresh() {
    try {
      home?.salesCashFlows?.[currentPeriod]
        ? setSalesCashFlows(home?.salesCashFlows?.[currentPeriod])
        : fetchSalesCashFlow(currentPeriod);
    } catch (e) {
      console.log(e);
      fetchSalesCashFlow(currentPeriod);
    }

    try {
      home?.homeAnalytics?.[currentPeriod]
        ? setHomeAnalyticsChartData(home?.homeAnalytics?.[currentPeriod])
        : fetchDashboardAnalytics(currentPeriod);
    } catch (e) {
      console.log(e);
      fetchDashboardAnalytics(currentPeriod);
    }
  }

  const setHomeAnalyticsChartData = (chartData) => {
    const customersData = {
      labels: chartData?.labels,
      pastData: chartData?.customers?.past,
      presentData: chartData?.customers?.present,
      pastTotal: chartData?.customers?.past.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
      presentTotal: chartData?.customers?.present.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
    };
    const purchaseData = {
      labels: chartData?.labels,
      pastData: chartData?.purchase?.past,
      presentData: chartData?.purchase?.present,
      pastTotal: chartData?.purchase?.past.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
      presentTotal: chartData?.purchase?.present.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
    };

    const saleData = {
      labels: chartData?.labels,
      pastData: chartData?.sale?.past,
      presentData: chartData?.sale?.present,
      pastTotal: chartData?.sale?.past.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
      presentTotal: chartData?.sale?.present.reduce(
        (partial_sum, a) => partial_sum + parseFloat(a || 0),
        0,
      ),
    };

    setSalesChart(saleData);
    setPurchaseChart(purchaseData);
    setCustomersChart(customersData);
  };
  async function fetchDashboardAnalytics(currentPeriodIndex) {
    try {
      let period = 'today';
      switch (currentPeriodIndex) {
        case 1:
          period = 'week';
          break;
        case 2:
          period = 'month';
          break;
        default:
          period = 'today';
          break;
      }

      const res = await apiRequest(`api/home_analytics/${period}`, null, 'GET');

      if (res?.results) setHomeAnalyticsChartData(res?.results);
      let dashAnalyticArray = home?.homeAnalytics
        ? [...home?.homeAnalytics]
        : [];
      dashAnalyticArray[currentPeriodIndex] = res?.results;
      dispatch(
        updateHomeStore({
          homeAnalytics: dashAnalyticArray,
        }),
      );
    } catch (error) {
      console.log(error);
      setHomeAnalyticsChartData({
        labels: ['0'],
        customers: { present: [0], past: [0] },
        purchase: { present: [0], past: [0] },
        sale: { present: [0], past: [0] },
      });
    }
  }
  const fetchSalesCashFlow = async (currentPeriodIndex = 0) => {
    let today = moment().format('YYYY-MM-DD');

    try {
      let start = today;

      let end = today;
      switch (currentPeriodIndex) {
        case 1:
          start = moment(today)
            .subtract((moment(today).day() + 6) % 7, 'days')
            .format('YYYY-MM-DD');

          break;
        case 2:
          start = moment(today).format('YYYY-MM').concat('-01');
          break;
      }
      const response = await apiRequest(
        `api/retail_sale_analytics/${start}/${end}`,
        null,
        'GET',
      );
      const salesCashFlow = {
        labels: response?.results.map(({ label }) => label),
        values: response?.results.map(({ value }) => value),
      };

      // console.log(home, 'fetchSalesCashFlow');
      setSalesCashFlows(salesCashFlow);
      let saleCashFlowArray = home?.salesCashFlows
        ? [...home?.salesCashFlows]
        : [];
      saleCashFlowArray[currentPeriodIndex] = salesCashFlow;

      dispatch(
        updateHomeStore({
          salesCashFlows: saleCashFlowArray,
        }),
      );
    } catch (error) {
      console.log(error);
      setSalesCashFlows({ labels: ['0'], values: [0] });
    }
  };

  const fetchLatestPermissions = async () => {
    const me = await apiRequest(`/auth/users/me`, null, 'GET');
    const now = new Date();

    if (me?.results?.user?.id) {
      const response = await apiRequest(
        `api/accounts/get_permissions/${me?.results?.user?.id}/${now}`,
        null,
        'GET',
      );

      dispatch(
        updatePermissionsStore({
          all: response?.results?.filter((eachPerm) => {
            return eachPerm.access === true;
          }),
        }),
      );
    }
  };

  const purchasePercent = percentCalc(
    purchaseChart?.presentTotal,
    purchaseChart?.pastTotal,
  );

  const salesPercent = percentCalc(
    salesChart?.presentTotal,
    salesChart?.pastTotal,
  );

  const customerPercent = percentCalc(
    customersChart?.presentTotal,
    customersChart?.pastTotal,
  );
  const risingIcon = (
    <span>
      <IncreaseArrow />
    </span>
  );
  const losingIcon = (
    <span>
      <DecreaseArrow />
    </span>
  );
  const Button = styled.button`
    height: 58px;
    padding: 4px 15px;
    border-radius: 8px;
    outline: 0;
    font-size: 11px;
    font-weight: 400;
    text-transform: none;
    margin: 0px 8px 0px 0px;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;
  `;

  const ButtonToggle = styled(Button)`
    background-color: #f0fbfc;
    color: #003471;
    border: 1.5px solid #e3f0f6;
    ${({ active }) =>
      active &&
      `
      border: 1px solid #e3f0f6;
      background-color: ${COLORS.blue_bright};
      color: white;
  `}
  `;

  function searchReports() {
    let search_results = allReports
      ?.filter((eachCard) =>
        eachCard.title.toLowerCase().includes(reportSearch.toLowerCase()),
      )
      ?.sort(function (a, b) {
        const aStarts = a?.title
          ?.toLowerCase()
          .startsWith(reportSearch.toLowerCase());
        const bStarts = b?.title
          ?.toLowerCase()
          .startsWith(reportSearch.toLowerCase());
        if (aStarts && bStarts)
          return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
        if (aStarts && !bStarts) return -1;
        if (!aStarts && bStarts) return 1;
        return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
      });
    return search_results;
  }

  function addToFavourites(module) {
    if (favourites.filter((f) => f.title == module.title)?.length > 0) {
      enqueueSnackbar(`Already added to favourites`, {
        variant: 'error',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else {
      if (favourites?.length >= 18) {
        enqueueSnackbar(`Max favorites limit reached (Max:18)`, {
          variant: 'error',
          autoHideDuration: 1500,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        return 0;
      }
      let favs = [...favourites];
      favs.push(module);
      if (module?.id) {
        setFavourites(favs);
        updateFavourites(module, 'add', favs);
      }
    }
  }

  function removeFromFavourites(module) {
    let favs = favourites.filter((f) => {
      return f?.title != module.title;
    });
    if (module?.id) {
      setFavourites(favs);
      updateFavourites(module, 'remove', favs);
    }
  }

  const updateFavourites = async (module, type, newState) => {
    try {
      let body = {
        card_id: module?.id,
        type: type,
        is_report: module?.is_report || false,
      };
      const res = await apiRequest(`api/update_favourites/`, body, 'POST');
      dispatch(
        updateModulesStore({
          favourites: newState,
        }),
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Could not update favourites`, {
        variant: 'error',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      if (type == 'add') {
        dispatch(
          updateModulesStore({
            favourites: newState?.filter((card) => card.id != module?.id),
          }),
        );
      }
      if (type == 'remove') {
        let favs = [...newState];
        favs.push(module);
        dispatch(
          updateModulesStore({
            favourites: favs,
          }),
        );
      }
    }
  };

  const keyMap = {
    sales_card: ['s', 'S'],
    sale_return_card: ['r', 'R'],
    purchase_card: ['p', 'P'],
    purchase_return_card: ['z', 'Z'],
    direct_sales_card: ['d', 'D'],
    inward_card: ['i', 'I'],
    transfer_in_card: ['x', 'X'],
    transfer_out_card: ['c', 'C'],
    stock_details_card: ['t', 'T'],
    receipts_card: ['n', 'N'],
    payments_card: ['m', 'M'],
    toggle_views: ['Ctrl+b'],
  };
  const handlers = {
    sales_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();
        if (branchType === 1) history.push('/sales');
        else history.push('/retail/sales');
      }
    },
    sale_return_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/sales_return');
        else history.push('/retail/sales_return');
      }
    },
    purchase_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/purchase');
        else history.push('/retail/purchase');
      }
    },
    purchase_return_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/purchase_return');
        else history.push('/retail/purchase_return');
      }
    },
    direct_sales_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/sale_retail');
        else history.push('/retail/direct_sales');
      }
    },
    inward_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/inward_order_home');
      }
    },
    transfer_in_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/transfer_in');
        else history.push('/retail/transfer_in');
      }
    },
    transfer_out_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        if (branchType === 1) history.push('/transfer_out');
        else history.push('/retail/transfer_out');
      }
    },
    stock_details_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();

        setTimeout(() => {
          history.push('/batch_details');
        }, 100);
      }
    },
    receipts_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();
        history.push('/receipts');
      }
    },
    payments_card: (event) => {
      if (home?.switchView == true) {
        event.preventDefault();
        history.push('/payments');
      }
    },
    toggle_views: (event) => {
      dispatch(
        updateHomeStore({
          switchView: !home?.switchView,
        }),
      );
    },
  };
  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: (event) => {
      const { target } = event;
      if (target && target.tagName) {
        return target.isContentEditable;
      }
      return false;
    },
  });

  const renderDashboardView = () => {
    return (
      <Grid
        container
        spacing={2}
        justify="space-between"
        style={{ marginTop: '6px', paddingRight: '8px' }}
      >
        <Grid item md={4}>
          <Card className={s.graphCard} outlined={true} elevation={0}>
            <CardContent className={s.tableCardContent}>
              <Grid container style={{ marginBottom: '8px' }}>
                <Grid item container>
                  <Grid item md={7}>
                    <p className={s.cardTitles}>
                      Total Sales{' '}
                      <span
                        className={s.increaseDecreaseValue}
                        style={{
                          color: salesPercent < 0 ? '#FF2828' : '#02BD63',
                        }}
                      >
                        {salesPercent + '% '}
                        {salesPercent < 0 ? losingIcon : risingIcon}{' '}
                      </span>
                    </p>
                  </Grid>
                  <Grid item md={5} style={{ textAlign: 'right' }}>
                    <p className={s.cardTitleValue}>
                      {numberFormat(salesChart?.presentTotal)}
                    </p>
                  </Grid>
                </Grid>
                <Grid item container style={{ marginTop: '8px' }}>
                  <Grid item sm={6} md={6} />
                  <Grid item sm={6} md={6} style={{ textAlign: 'right' }}>
                    <label className={s.chartGreyLabel}>
                      {lastPeriodLabel}
                    </label>
                    <label className={s.chartBlueValue}>
                      {numberFormat(salesChart?.pastTotal)}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <AreaChart
                key="chart1"
                item
                data={salesChart}
                eid="chart1"
              ></AreaChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card className={s.graphCard} outlined={true} elevation={0}>
            <CardContent className={s.tableCardContent}>
              <Grid container style={{ marginBottom: '8px' }}>
                <Grid item container>
                  <Grid item md={7}>
                    <p className={s.cardTitles}>
                      Total Purchase{' '}
                      <span
                        className={s.increaseDecreaseValue}
                        style={{
                          color: purchasePercent < 0 ? '#FF2828' : '#02BD63',
                        }}
                      >
                        {purchasePercent + '% '}
                        {purchasePercent < 0 ? losingIcon : risingIcon}{' '}
                      </span>
                    </p>
                  </Grid>
                  <Grid item md={5} style={{ textAlign: 'right' }}>
                    <p className={s.cardTitleValue}>
                      {numberFormat(purchaseChart?.presentTotal)}
                    </p>
                  </Grid>
                </Grid>
                <Grid item container style={{ marginTop: '8px' }}>
                  <Grid item sm={6} md={6} />

                  <Grid item sm={6} md={6} style={{ textAlign: 'right' }}>
                    <label className={s.chartGreyLabel}>
                      {lastPeriodLabel}
                    </label>
                    <label className={s.chartBlueValue}>
                      {numberFormat(purchaseChart?.pastTotal)}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <AreaChart
                item
                key="chart2"
                data={purchaseChart}
                eid="chart2"
              ></AreaChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card className={s.graphCard} outlined={true} elevation={0}>
            <CardContent className={s.tableCardContent}>
              <Grid container style={{ marginBottom: '8px' }}>
                <Grid item container>
                  <Grid item md={7}>
                    <p className={s.cardTitles}>
                      Total Customers{' '}
                      <span
                        className={s.increaseDecreaseValue}
                        style={{
                          color: customerPercent < 0 ? '#FF2828' : '#02BD63',
                        }}
                      >
                        {customerPercent + '% '}
                        {customerPercent < 0 ? losingIcon : risingIcon}{' '}
                      </span>
                    </p>
                  </Grid>
                  <Grid item md={5} style={{ textAlign: 'right' }}>
                    <p className={s.cardTitleValue}>
                      {numberFormat(customersChart?.presentTotal)}
                    </p>
                  </Grid>
                </Grid>
                <Grid item container style={{ marginTop: '8px' }}>
                  <Grid item sm={6} md={6} />

                  <Grid item sm={6} md={6} style={{ textAlign: 'right' }}>
                    <label className={s.chartGreyLabel}>
                      {lastPeriodLabel}
                    </label>
                    <label className={s.chartBlueValue}>
                      {customersChart?.pastTotal}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <AreaChart
                item
                data={customersChart}
                eid="chart3"
                key="chart3"
              ></AreaChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={s.tableCard} outlined={true} elevation={0}>
            <CardContent className={s.tableCardContent}>
              <p className={s.cardTitles}>Financials</p>
              <Grid container style={{ marginTop: '20px', padding: '0px 8px' }}>
                <Grid item md={6}>
                  <Box className={s.finVerticalItems}>
                    <Grid container column style={{ paddingTop: '4px' }}>
                      <Grid item md={2} sm={2}>
                        <span
                          className={s.analyticsIcon}
                          style={{ backgroundColor: '#E9F3FF' }}
                        >
                          <CashOnHand />
                        </span>
                      </Grid>
                      <Grid item md={10} sm={10}>
                        <p className={s.analyticsLabel}>Cash on hand</p>
                        <p className={s.analyticsValue}>{numberFormat(0)}</p>
                      </Grid>
                    </Grid>
                    <Grid container column style={{ paddingTop: '4px' }}>
                      <Grid item md={2} sm={2}>
                        <span
                          className={s.analyticsIcon}
                          style={{ backgroundColor: '#E9F3FF' }}
                        >
                          <BankBalance />
                        </span>
                      </Grid>
                      <Grid item md={10} sm={10}>
                        <p className={s.analyticsLabel}>Bank Balance</p>
                        <p className={s.analyticsValue}>{numberFormat(0)}</p>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box className={s.finVerticalItems}>
                    <Grid container column style={{ paddingTop: '4px' }}>
                      <Grid item md={2} sm={2}>
                        <span
                          className={s.analyticsIcon}
                          style={{ backgroundColor: '#F9EEDD' }}
                        >
                          <PaymentsOutlined />
                        </span>
                      </Grid>
                      <Grid item md={10} sm={10}>
                        <p className={s.analyticsLabel}>Payments</p>
                        <p className={s.analyticsValue}>{numberFormat(0)}</p>
                      </Grid>
                    </Grid>
                    <Grid container column style={{ paddingTop: '4px' }}>
                      <Grid item md={2} sm={2}>
                        <span
                          className={s.analyticsIcon}
                          style={{ backgroundColor: '#F9EEDD' }}
                        >
                          <SupplierPDC />
                        </span>
                      </Grid>
                      <Grid item md={10} sm={10}>
                        <p className={s.analyticsLabel}>Supplied POD</p>
                        <p className={s.analyticsValue}>{numberFormat(0)}</p>
                      </Grid>
                    </Grid>
                    <Grid container column style={{ paddingTop: '4px' }}>
                      <Grid item md={2} sm={2}>
                        <span
                          className={s.analyticsIcon}
                          style={{ backgroundColor: '#F9EEDD' }}
                        >
                          <PayablesOutlined />
                        </span>
                      </Grid>
                      <Grid item md={10} sm={10}>
                        <p className={s.analyticsLabel}>Payables</p>
                        <p className={s.analyticsValue}>{numberFormat(0)}</p>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={s.tableCard} outlined elevation={0}>
            <CardContent className={s.tableCardContent}>
              <p className={s.cardTitles}>Sales Cashflow</p>
              <DoughnutChart
                item
                data={salesCashFlows}
                eid="chart1"
              ></DoughnutChart>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item md={12}>
          <Card className={s.tableCard} outlined={true} elevation={0}>
            <CardContent className={s.tableCardContent}>
              <Grid container>
                <Grid item md={6}>
                  <p className={s.cardTitles}>Inventory</p>
                </Grid>
                <Grid item md={6}>
                  <Grid container justify="flex-end" alignItems="baseline">
                    <p
                      style={{
                        color: '#7e7e7e',
                        margin: '0px',
                        fontSize: '11px',
                      }}
                    >
                      Closing stock value
                    </p>
                    <p className={s.cardTitleValue}>
                      {numberFormat(inventoryTableTabs?.closing_data[0].value)}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ width: 'calc(100% + 8px)', marginTop: '16px' }}
              >
                {inventoryTableTabs?.inventory_data.map((tab, index) => (
                  <ButtonToggle
                    active={inventoryActive === tab.key}
                    onClick={() => {
                      setInventoryActive(tab.key);
                      setSelectedTab(index);
                    }}
                  >
                    {tab.label}
                    <p
                      style={{
                        fontSize: '14px',
                        margin: '8px 0 0',
                        fontWeight: '500',
                      }}
                    >
                      {numberFormat(tab.value)}
                    </p>
                  </ButtonToggle>
                ))}
              </Grid>
              <Grid container style={{ width: '100%', marginTop: '16px' }}>
                <div style={{ height: '300px', width: '100%' }}>
                  <TableComponent
                    columns={columns}
                    rows={rows}
                    rowClickable={false}
                    colorOnRowSelected={false}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    );
  };

  function dialogClose() {
    setReportDialogOpen(false);
  }

  const renderCardView = () => {
    function renderedFavoriteMenu() {
      return (
        <Accordion
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'transparent',
            margin: '0',
            flexDirection: 'row-reverse',
          }}
          elevation={0}
          expanded={favoritesExpanded}
          onChange={(ev) => {
            setFavoritesExpanded(!favoritesExpanded);
          }}
        >
          <AccordionSummary
            expandIcon={
              <span
                style={{
                  backgroundColor: '#e4f2fa',
                  borderRadius: '50%',
                  display: 'flex',
                  padding: '3px 2px 2px',
                }}
              >
                <ExpandMoreIcon style={{ fill: '#5d89a0' }} />
              </span>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item>
                <p className={s.categoryTitle}>Favourites</p>
              </Grid>
              <Grid item style={{ flexGrow: 1, padding: '0 8px 0 16px' }}>
                <Divider
                  style={{ backgroundColor: '#e4f2fa', height: '1.5px' }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container style={{ width: '100%', margin: '0 0 20px 0' }}>
            {favourites.map((eachCard) => {
              return (
                <Grid item md={2} sm={2}>
                  <Card
                    className="homeModuleCard"
                    variant="outlined"
                    onClick={() => history.push(eachCard?.route)}
                  >
                    <CardContent className={s.card_content_shortcuts_trail}>
                      <Grid container style={{ height: '100%' }}>
                        <Grid item md={11} sm={11}>
                          <Grid
                            container
                            alignItems="center"
                            style={{ height: '100%' }}
                          >
                            <div
                              className={s.colorStrip}
                              style={{
                                width: '10px',
                                height: '100%',
                                backgroundColor: '#eebc3a',
                                color: '#eebc3a',
                              }}
                            >
                              .
                            </div>
                            <Grid
                              container
                              item
                              style={{
                                width: 'calc(100% - 10px)',
                                height: '100%',
                              }}
                              alignContent="center"
                            >
                              <p className={s.title_trail}>{eachCard?.title}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          md={1}
                          sm={1}
                          style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid item>
                            {eachCard.shortcut == '*' ? (
                              <span className={s.newIndication} />
                            ) : (
                              <div className={s.keyboardShortcutContainer}>
                                <h4
                                  style={{
                                    color: COLORS.app_secondary,
                                    margin: '0px',
                                  }}
                                >
                                  {eachCard?.shortcut ? (
                                    eachCard.shortcut
                                  ) : (
                                    <span>&nbsp;</span>
                                  )}
                                </h4>
                              </div>
                            )}
                          </Grid>
                          <Grid item className="homeModuleCardFavorite">
                            <Tooltip
                              placement="top"
                              title={
                                <p className="tooltip">
                                  Remove from favourites
                                </p>
                              }
                              enterDelay={200}
                              disableFocusListener
                              disableTouchListener
                              arrow
                            >
                              <IconButton
                                style={{ padding: '0', marginBottom: '5px' }}
                                onClick={(e) => {
                                  removeFromFavourites(eachCard);
                                  // Prevent trigger of parent event (clicking card)
                                  if (!e) var e = window.event;
                                  e.cancelBubble = true;
                                  e.stopPropagation();
                                }}
                              >
                                <GradeRoundedIcon
                                  style={{ fontSize: '15px', color: '#eebc3a' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Accordion>
      );
    }
    function renderedMenu() {
      return allModules?.map((each, index) => (
        <Accordion
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'transparent',
            margin: '0',
            flexDirection: 'row-reverse',
          }}
          elevation={0}
          defaultExpanded={true}
          expanded={each?.expanded}
          onChange={(ev) => {
            let allMods = [...allModules];
            allMods[index] = {
              ...allMods[index],
              expanded: !allMods[index]?.expanded,
            };
            setAllModules(allMods);
          }}
        >
          <AccordionSummary
            expandIcon={
              <span
                style={{
                  backgroundColor: '#e4f2fa',
                  borderRadius: '50%',
                  display: 'flex',
                  padding: '3px 2px 2px',
                }}
              >
                <ExpandMoreIcon style={{ fill: '#5d89a0' }} />
              </span>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item>
                <p className={s.categoryTitle}>
                  {each?.title}{' '}
                  {each?.title == 'Reports' &&
                  each?.submenu?.filter((mod) => mod.shortcut == '*')?.length >
                    0 ? (
                    <span className={s.newIndicationText}>
                      {
                        each?.submenu?.filter((mod) => mod.shortcut == '*')
                          ?.length
                      }{' '}
                      NEW
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              </Grid>
              <Grid item style={{ flexGrow: 1, padding: '0 8px 0 16px' }}>
                <Divider
                  style={{ backgroundColor: '#e4f2fa', height: '1.5px' }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Grid container style={{ width: '100%', margin: '0 0 20px 0' }}>
            {each?.submenu
              ?.slice(
                0,
                each?.title === 'Reports' && each.submenu.length > 11
                  ? 11
                  : each.submenu.length,
              )
              ?.map((eachCard) => {
                return (
                  <Grid item md={2} sm={2}>
                    <Card
                      className="homeModuleCard"
                      variant="outlined"
                      onClick={() => history.push(eachCard?.route)}
                    >
                      <CardContent className={s.card_content_shortcuts_trail}>
                        <Grid container style={{ height: '100%' }}>
                          <Grid item md={11} sm={11}>
                            <Grid
                              container
                              alignItems="center"
                              style={{ height: '100%' }}
                            >
                              <div
                                className={s.colorStrip}
                                style={{
                                  width: '10px',
                                  height: '100%',
                                  backgroundColor: each.sectionColorCode,
                                  color: each.sectionColorCode,
                                }}
                              >
                                .
                              </div>
                              <Grid
                                container
                                item
                                style={{
                                  width: 'calc(100% - 10px)',
                                  height: '100%',
                                }}
                                alignContent="center"
                              >
                                <p className={s.title_trail}>
                                  {eachCard?.title}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            md={1}
                            sm={1}
                            style={{
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item>
                              {eachCard.shortcut == '*' ? (
                                <span className={s.newIndication} />
                              ) : (
                                <div className={s.keyboardShortcutContainer}>
                                  <h4
                                    style={{
                                      color: COLORS.app_secondary,
                                      margin: '0px',
                                    }}
                                  >
                                    {eachCard?.shortcut ? (
                                      eachCard.shortcut
                                    ) : (
                                      <span>&nbsp;</span>
                                    )}
                                  </h4>
                                </div>
                              )}
                            </Grid>
                            <Grid item className="homeModuleCardFavorite">
                              <Tooltip
                                placement="top"
                                title={
                                  <p className="tooltip">Add to favourites</p>
                                }
                                enterDelay={200}
                                disableFocusListener
                                disableTouchListener
                                arrow
                              >
                                <IconButton
                                  style={{
                                    padding: '0',
                                    marginBottom: '5px',
                                  }}
                                  onClick={(e) => {
                                    addToFavourites(eachCard);
                                    // Prevent trigger of parent event (clicking card)
                                    if (!e) var e = window.event;
                                    e.cancelBubble = true;
                                    e.stopPropagation();
                                  }}
                                >
                                  <StarBorderRoundedIcon
                                    style={{
                                      fontSize: '15px',
                                      fill: '#eebc3a',
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            {each?.title === 'Reports' && each.submenu.length > 11 ? (
              <>
                <Grid style={{ position: 'relative' }} item md={2} sm={2}>
                  <Badge
                    style={{
                      position: 'absolute',
                      right: 32,
                      top: 16,
                      fontSize: '10px',
                    }}
                    badgeContent={`+${each.submenu.length - 11}`}
                    color="primary"
                    alignitems="center"
                  ></Badge>
                  <Card
                    className="homeModuleCard"
                    variant="outlined"
                    onClick={() => {
                      setReportDialogOpen(true);
                    }}
                  >
                    <CardContent className={s.card_content_shortcuts_trail}>
                      <Grid container style={{ height: '100%' }}>
                        <Grid container item md={11} sm={11}>
                          <Grid
                            container
                            item
                            alignItems="center"
                            style={{ height: '100%' }}
                          >
                            <Grid
                              item
                              container
                              style={{
                                marginLeft: '10px',
                                width: 'calc(100% - 10px)',
                                height: '100%',
                              }}
                              alignContent="center"
                            >
                              <p className={s.title_trail}>View More</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Accordion>
      ));
    }
    function renderSectionCollapse(state, text) {
      return (
        <Grid item md={12}>
          <Grid
            container
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingRight: '8px',
              cursor: 'pointer',
              marginTop: '16px',
            }}
            onClick={(event) => {
              handleCollapseClick(state);
            }}
          >
            <Grid
              item
              style={{
                transform: `rotate(${state ? 180 : 0}deg)`,
                transition: '0.4s',
              }}
            >
              <Collapse />
            </Grid>
            <p
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#5d89a0',
                margin: '0',
                width: '72px',
                textAlign: 'right',
              }}
            >
              {text || ''}
            </p>
          </Grid>
        </Grid>
      );
    }
    function handleCollapseClick(state) {
      setSectionCollapseState({
        text: state ? 'Expand All' : 'Collapse All',
        collapse: !state,
      });
      let allMods = [...allModules]?.map((mod) => ({
        ...mod,
        expanded: !state,
      }));
      setAllModules(allMods);
      setFavoritesExpanded(!state);
    }
    return (
      <Grid container>
        {renderSectionCollapse(
          sectionCollapseState?.collapse,
          sectionCollapseState?.text,
        )}
        {favourites?.length > 0 ? renderedFavoriteMenu() : ''}
        {renderedMenu()}
      </Grid>
    );
  };

  const renderReportsDialog = (input, type) => {
    function generateCard(eachCard) {
      return (
        <Grid item md={3}>
          <Card
            className="homeModuleCard"
            variant="outlined"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 8px 16px 8px',
            }}
            onClick={() => history.push(eachCard.route)}
          >
            <CardContent className={s.card_content_shortcuts_trail}>
              <Grid container style={{ height: '100%' }}>
                <Grid item md={11} sm={11}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    <div
                      className={s.colorStrip}
                      style={{
                        width: '10px',
                        height: '100%',
                        backgroundColor: reportsMenu.sectionColorCode,
                        color: reportsMenu.sectionColorCode,
                      }}
                    >
                      &nbsp;
                    </div>
                    <Grid
                      container
                      item
                      style={{
                        width: 'calc(100% - 10px)',
                        height: '100%',
                      }}
                      alignContent="center"
                    >
                      <p className={s.title_trail}>{eachCard?.title}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={1}
                  sm={1}
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    {eachCard.shortcut == '*' ? (
                      <span className={s.newIndication} />
                    ) : (
                      <div className={s.keyboardShortcutContainer}>
                        <h4
                          style={{
                            color: COLORS.app_secondary,
                            margin: '0px',
                          }}
                        >
                          {eachCard?.shortcut ? (
                            eachCard.shortcut
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </h4>
                      </div>
                    )}
                  </Grid>
                  <Grid item className="homeModuleCardFavorite">
                    <Tooltip
                      placement="top"
                      title={<p className="tooltip">Add to favourites</p>}
                      enterDelay={200}
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <IconButton
                        style={{ padding: '0', marginBottom: '5px' }}
                        onClick={(e) => {
                          addToFavourites(eachCard);
                          // Prevent trigger of parent event (clicking card)
                          if (!e) var e = window.event;
                          e.cancelBubble = true;
                          e.stopPropagation();
                        }}
                      >
                        <StarBorderRoundedIcon
                          style={{
                            fontSize: '15px',
                            fill: '#eebc3a',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    }
    function generateTabContent(group) {
      return (
        <>
          {group && Object.keys(group)?.includes('sub_group')
            ? group?.sub_group?.map((subGroup, subIndex) => {
                return (
                  <>
                    <p
                      className={s.reportSubGroupTitle}
                      style={{ marginTop: subIndex == 0 ? '0px' : '24px' }}
                    >
                      {subGroup?.group_name}
                    </p>
                    {subGroup?.reports?.map((eachCard) => {
                      return generateCard(eachCard);
                    })}
                  </>
                );
              })
            : ''}
          {group && Object.keys(group)?.includes('reports')
            ? group?.reports?.map((eachCard) => {
                return generateCard(eachCard);
              })
            : ''}
        </>
      );
    }
    function generateSearchResultsContent(results) {
      return (
        <>
          {results?.length > 0 ? (
            results?.map((eachCard) => {
              return generateCard(eachCard);
            })
          ) : (
            <>
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                No Results Found
              </p>
            </>
          )}
        </>
      );
    }

    if (type == 0)
      return (
        <>
          <Grid
            item
            md={3}
            style={{
              backgroundColor: '#edf5ff',
              borderRight: 'solid 2px #D9E7ED',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <MenuList
              variant="selectedMenu"
              style={{ padding: 0, background: 'none' }}
              disableRipple
            >
              {input?.map((group, index) => (
                <StyledReportMenuItem
                  disableRipple
                  key={index}
                  selected={index === selectedReportTab}
                  onClick={(e) => {
                    setSelectedReportTab(index);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    {group?.group_name}
                  </div>
                </StyledReportMenuItem>
              ))}
            </MenuList>
          </Grid>
          <Grid item md={9} style={{ height: '100%', overflowY: 'auto' }}>
            <Grid
              container
              style={{
                height: '100%',
                padding: '32px 24px',
                alignContent: 'flex-start',
              }}
            >
              {generateTabContent(input?.[selectedReportTab])}
            </Grid>
          </Grid>
        </>
      );
    else if (type == 1) {
      return (
        <>
          <Grid
            item
            md={3}
            style={{
              backgroundColor: '#edf5ff',
              borderRight: 'solid 2px #D9E7ED',
            }}
          >
            <MenuList
              variant="selectedMenu"
              style={{ padding: 0, background: 'none' }}
              disableRipple
            >
              <StyledReportMenuItem disableRipple key={0} selected={true}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  Search Results
                </div>
              </StyledReportMenuItem>
            </MenuList>
          </Grid>
          <Grid item md={9} style={{ height: '100%', overflowY: 'auto' }}>
            <Grid
              container
              style={{
                height: '100%',
                padding: '32px 24px',
                alignContent: input?.length > 0 ? 'flex-start' : 'center',
              }}
            >
              {generateSearchResultsContent(input)}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return permissions?.branch_type == 3 ? (
    <div className={s.root_noSideBar}>
      <div className={s.container}>
        <HqDashHome />
      </div>
    </div>
  ) : (
    <div className={s.root}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
        <div className={s.container}>
          <Grid
            container
            justify="space-between"
            style={{ marginTop: '8px', paddingRight: '8px' }}
          >
            <Grid
              item
              md={6}
              style={{
                visibility:
                  home?.switchView == undefined
                    ? 'hidden'
                    : home?.switchView
                    ? 'hidden'
                    : '',
              }}
            >
              <DropdownButton
                title={timePeriods[currentPeriod]}
                icon={<KeyboardArrowDownIcon />}
                executeClick={handleClick}
                customWidth="116px"
              />

              <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {timePeriods.map((periodOption, index) => {
                  return (
                    <StyledMenuItem
                      onClick={() => {
                        setCurrentPeriod(index);
                        handleClose();
                        switch (index) {
                          case 1:
                            setLastPeriodLabel('Last Week');
                            break;
                          case 2:
                            setLastPeriodLabel('Last Month');
                            break;
                          default:
                            setLastPeriodLabel('Yesterday');
                            break;
                        }

                        // fetchDashboardAnalytics(index);
                        // fetchSalesCashFlow(index);
                      }}
                    >
                      <ListItemText primary={periodOption} />
                    </StyledMenuItem>
                  );
                })}
              </StyledMenu>
            </Grid>
            <Grid container item md={6} justify="flex-end" alignItems="center">
              <p
                style={{
                  margin: '0px 8px',
                  visibility:
                    home?.switchView == undefined
                      ? 'hidden'
                      : home?.switchView
                      ? 'hidden'
                      : '',
                }}
              >
                Last Updated: {lastUpdated}
              </p>
              <IconButton
                style={{
                  borderRadius: '6px',
                  border: '1px solid #CAD8DF',
                  backgroundColor: '#fff',
                  height: '36px',
                  width: '36px',
                  padding: '2px',
                  visibility: home?.switchView ? 'hidden' : '',
                  // display: home?.switchView && !isSuperAdmin ? 'none' : 'block',
                }}
                onClick={() => {
                  dispatch(
                    updateHomeStore({
                      lastUpdated: false,
                    }),
                  );
                }}
              >
                <RefreshFilled />
              </IconButton>

              {dashboardAccess && (
                <CustomSwitch
                  style={{
                    // display: home?.switchView && !isAdminUser ? 'none' : 'block',
                    display:
                      home?.switchView && !dashboardAccess ? 'none' : 'block',
                  }}
                  checked={home?.switchView || false}
                  icon={
                    <span className={s.toggleThumbContainer}>
                      <ToggleDashboard />
                    </span>
                  }
                  checkedIcon={
                    <span className={s.toggleThumbContainer}>
                      <ToggleCards />
                    </span>
                  }
                  disableRipple
                  onChange={(event) => {
                    dispatch(
                      updateHomeStore({
                        switchView: !home?.switchView,
                      }),
                    );
                  }}
                />
              )}
            </Grid>
          </Grid>

          {home?.switchView == undefined ? (
            <></>
          ) : home?.switchView ? (
            renderCardView()
          ) : branchType === 1 ? (
            renderDashboardView()
          ) : branchType === 2 ? (
            history.push('/dashboard')
          ) : null}
        </div>
      </GlobalHotKeys>

      <Dialog
        fullWidth
        onClose={dialogClose}
        open={reportDialogOpen}
        maxWidth="lg"
        id="moreReportsDialog"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={dialogClose}
          style={{ paddingLeft: '16px', borderBottom: 'solid 2px #D9E7ED' }}
          searchInput={
            <CssTextField
              style={{
                width: '384px',
                marginRight: '36px',
              }}
              autoFocus
              className={s.inputfieldset}
              label="Search Reports"
              variant="outlined"
              margin="normal"
              type="text"
              autoComplete="off"
              InputProps={{
                endAdornment: <SearchIcon fontSize="small" />,
              }}
              contentEditable
              suppressContentEditableWarning
              value={reportSearch || ''}
              onChange={(event) => setReportSearch(event.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                }
              }}
            />
          }
        >
          Reports&nbsp;{`(${allReports?.length})`}
        </DialogTitle>
        <DialogContent style={{ overflowY: 'clip' }}>
          <Grid
            container
            style={{
              height: '100%',
              overflowY: 'hidden',
              padding: '0',
            }}
          >
            {reportSearch
              ? renderReportsDialog(searchReports(), 1)
              : renderReportsDialog(reportsMenu, 0)}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Home.propTypes = {};
