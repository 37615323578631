import React from 'react';
import Home from './Home';
import Layout from 'components/Layout/Layout';

const helpWindow = {
  subText:
    'This is your homescreen. You can view your dashboard or navigate to various modules',
  options: [
    {
      text: 'Keyboard Shortcuts',
      icon: 'Keyboard',
      active: true,
      action: 'show_shortcuts',
    },
    {
      text: 'Tutorials',
      icon: 'Presentation',
      active: false,
      action: 'show_tutorials',
    },
    { text: "What's new ?", icon: 'Zap', active: true, action: 'whats_new' },
  ],
  shortcuts: [{ function: 'Switch Views', shortcut: 'Ctrl + B' }],
};

async function action() {
  return {
    title: 'Home',
    chunks: ['home'],
    component: (
      <Layout helpWindow={helpWindow}>
        <Home />
      </Layout>
    ),
  };
}

export default action;
