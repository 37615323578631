import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import apiRequest from 'utils/api';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Header from '../Header/Header_new';
import ColorFilledButton from 'components/Buttons/ColorFilledButton';
import { useInventoryManagement } from 'routes/inventory_management/inventoryMangementCustomHook';
import history from 'utils/history';

export default function Layout({
  children,
  title = '',
  header = true,
  screenShortcuts = {},
  helpWindow = {},
  warnNavigationAway = false,
  adminModuleKey = '',
}) {
  useStyles(s, normalizeCss);
  const inventoryHook = useInventoryManagement();
  const [tokenExpired, setTokenExpired] = React.useState(false);
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  React.useEffect(() => {
    if (window.location.href.includes('#token_expired')) {
      setTokenExpired(true);
      // localStorage.removeItem('username');
      // localStorage.removeItem('tenent');
      // localStorage.removeItem('userId');
      // localStorage.removeItem('authorization');
      // history.push('/login');
    } else {
      setTokenExpired(false);
    }
  }, [window.location.href]);

  function navigateAway(route, callBackFn = null) {
    if (
      window.confirm(
        'NOTE: All unsaved changes will be lost !\nDo you want to go back ?',
      )
    ) {
      if ((adminModuleKey ?? '') != '')
        inventoryHook.updateAdminEditLockFlag(
          adminModuleKey,
          route,
          callBackFn,
        );
    }
  }

  const renderHeader = () => {
    if (header) {
      return (
        <>
          <Header
            title={title}
            screenShortcuts={screenShortcuts}
            helpWindow={helpWindow}
            warnNavigationAway={warnNavigationAway}
            navigateAway={navigateAway}
            subMenuOpen={subMenuOpen}
            setSubMenuOpen={setSubMenuOpen}
          />

          {/* BACKDROP */}
          {subMenuOpen && <div className={s.backdrop} />}
        </>
      );
    }
  };

  const handleLogout = async () => {
    // clearSession();
    await apiRequest(`/auth/logout`, null, 'GET').then((response) => {
      clearSession(response);
    });
  };

  const clearSession = (logout = null) => {
    console.log(logout);
    localStorage.removeItem('username');
    localStorage.removeItem('tenent');
    localStorage.removeItem('userId');
    localStorage.removeItem('authorization');
    history.push('/login');
  };

  const renderLoginExpired = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '9999',
          backdropFilter: 'blur(2px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          style={{
            backgroundColor: '#fff',
            height: 'auto',
            width: '332px',
            borderRadius: '10px',
            padding: '32px 24px',
          }}
        >
          <Grid item>
            <p
              style={{
                fontSize: '16px',
                fontWeight: '500',
                margin: '0 0 16px 0',
              }}
            >
              Your Session Expired !
            </p>
            <p
              style={{ fontSize: '13px', color: '#777', margin: '0 0 24px 0' }}
            >
              Your login has timed-out or you have logged into a different
              system. Please login again to continue using the application.
            </p>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <ColorFilledButton
              title="Login"
              buttonType="success"
              executeClick={() => handleLogout()}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {tokenExpired && renderLoginExpired()}

      {renderHeader()}

      {children}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
