export function updateSpecificStore(actionTypes) {
  return payload => dispatch => {
    dispatch({ type: actionTypes.updateStore, payload });
  };
}

export function resetSpecificStore(actionTypes) {
  return payload => dispatch => {
    dispatch({ type: actionTypes.resetStore, payload });
  };
}

export function setDefaultActions(actionTypes) {
  return {
    [actionTypes.updateStore]: updateSpecificStore(actionTypes),
    [actionTypes.resetStore]: resetSpecificStore(actionTypes),
  };
}
