import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.piecelabel.js';

export default function DoughnutChart({ data, eid, styles = {} }) {
  const data_c = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient_first = ctx.createLinearGradient(0, 0, 0, 200);
    gradient_first.addColorStop(0, '#4F9FFF');
    gradient_first.addColorStop(1, '#0064DA');

    const gradient_sec = ctx.createLinearGradient(0, 0, 0, 200);
    gradient_sec.addColorStop(0, '#F98066');
    gradient_sec.addColorStop(1, '#DA4E00');

    const gradient_third = ctx.createLinearGradient(0, 0, 0, 200);
    gradient_third.addColorStop(0, '#B4B4B4');
    gradient_third.addColorStop(1, '#707070');

    const gradient_fourth = ctx.createLinearGradient(0, 0, 0, 200);
    gradient_fourth.addColorStop(0, '#34DBDB');
    gradient_fourth.addColorStop(1, '#00ADAD');

    // console.log(data, 'data==========');
    const labels = data?.labels || ['Cash', 'Card', 'UPI', 'Other'];
    const values = data?.values || [0, 0, 0, 0];

    return {
      pieceLabel: {
        render: 'value',
        fontColor: '#fff',
        fontWeight: 'bold',
      },
      //   labels: ['Cash', 'Card', 'UPI', 'Other'],
      labels: [...labels],
      datasets: [
        {
          label: 'First dataset',
          //   backgroundColor: gradient_first, // Put the gradient here as a fill color
          //   borderColor: '#FF2828',
          borderWidth: 0,
          pointRadius: 0,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          },
          //   pointColor: '#fff',
          //   pointStrokeColor: '#ff6c23',
          //   pointHighlightFill: '#fff',
          //   pointHighlightStroke: '#ff6c23',
          //   data: [20, 32, 15, 24],
          data: [...values],
          backgroundColor: [
            gradient_first,
            gradient_sec,
            gradient_fourth,
            gradient_third,
          ],
          //   hoverOffset: 4,
        },
      ],
    };
  };

  var options = {
    pieceLabel: {
      render: 'percentage',
      precision: 1,
      overlap: true,

      fontColor: '#fff',

      color: '#fff',
    },
    responsive: true,
    datalabels: {
      display: true,
      align: 'bottom',
      backgroundColor: '#ccc',
      borderRadius: 3,
      font: {
        size: 18,
      },
    },

    maintainAspectRatio: false,
    cutoutPercentage: 70,
    rotation: -1.0 * Math.PI - (25 / 180) * Math.PI,
    // datasetStrokeWidth: 3,
    // pointDotStrokeWidth: 4,
    // scaleLabel: "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",
    legend: {
      display: true,
      position: 'right',
      pointStyle: 'circle',
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    segmentShowStroke: false,
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
            drawTicks: false,
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false,
            drawTicks: false,
            drawOnChartArea: false,
          },
        },
      ],

      tooltips: {
        enabled: false,
      },
      //   x: {
      //     grid: {
      //       display: false,
      //     },
      //   },
      //   xAxes: [
      //     {
      //       gridLines: {
      //         drawOnChartArea: false,
      //       },
      //     },
      //   ],
      //   yAxes: [
      //     {
      //       gridLines: {
      //         drawTicks: false,
      //         drawOnChartArea: false,
      //       },
      //       ticks: {
      //         padding: 10,
      //       },
      //     },
      //   ],
    },
  };

  //   const canvas = document.createElement('id');
  //   const chartData = getData(eid);

  return (
    <div className="App" style={{ height: '185px' }}>
      <Doughnut data={data_c} options={options} />
    </div>
  );
}
