export const COLORS = {
  app_primary: '#1c5268',
  app_secondary: '#02aa7b',
  app_secondary_light: '#5bd9b5',
  app_secondary_dark: '#02845f',
  app_yellow: '#F7B519',
  app_red: '#e24545',
  app_red_dark: '#ca2f2f',
  app_red_hover: '#FFEFEE',
  app_sunset: '#FCA189',
  app_moss_green: '#D5F4ED',
  white: '#ffffff',
  cta_blue: '#003471',
  cta_blue_light: '#004AA0',
  hover_blue: '#EAF0F3',
  black: '#222222',
  blue_bright: '#397BC8',
  blue_sky: '#c7eafa',
  pale_red: '#FFE1DF',
  bright_red: '#F04134',
  rp_green: '#07B67B',
  rp_grey: '#DDDDDD',

  // NEW SETUP ////////////////////////////////////////////
  theme_primary: '#003471',
  theme_primary_text: '#003471',
  login_bg: '#003471',
  login_card_bg: 'rgb(255, 255, 255, 0.07)',
  login_card_primary_text: '#fff',
  login_card_tc: 'rgb(255,255,255,0.7)',
  login_card_span: 'rgb(255,255,255,0.1)',
  login_button: '#c4f049',
  login_button_text: '#003471',
  login_description_bg: '#e0f0ff',
  login_textfield_bg: 'transparent',
};
