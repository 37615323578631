import React, { forwardRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DefaultButton from '@material-ui/core/Button';
import { COLORS } from '../../constants/colors.js';
import clsx from 'clsx';

const UnfilledButton = forwardRef(
  (
    {
      title,
      keyboardShortcut,
      buttonSize = 'medium',
      executeClick,
      customMargin,
      customHeight,
      customWidth,
      buttonType,
      disabled,
      className,
    },
    ref,
  ) => {
    var button_height;
    var font_size;
    if (buttonSize === 'small') {
      button_height = '28px';
      font_size = '11px';
    } else if (buttonSize === 'medium') {
      button_height = '36px';
      font_size = '12px';
    } else if (buttonSize === 'large') {
      button_height = '52px';
      font_size = '13px';
    }

    var btn_color = COLORS.cta_blue;
    var btn_color_hover = COLORS.cta_blue_light;
    if (buttonType == 'alert') {
      var btn_color = COLORS.app_red;
      var btn_color_hover = COLORS.app_red_dark;
    } else if (buttonType == 'success') {
      var btn_color = '#02aa7b';
      var btn_color_hover = '#068965';
    }

    const styles = makeStyles({
      root: {
        color: '#ffffff',
        fontSize: font_size,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        float: 'left',
        width: customWidth ? customWidth : '100%',
        borderRadius: '6px',
        height: customHeight ? customHeight : button_height,
        margin: customMargin ? customMargin : '0px',
        lineHeight: 1.5,
        textTransform: 'none',
        backgroundColor: btn_color,
        '&:hover': {
          backgroundColor: btn_color_hover,
        },
      },
    });
    const classes = styles();

    return (
      <DefaultButton
        ref={ref}
        variant="contained"
        onClick={executeClick}
        className={clsx(className, classes.root)}
        disabled={disabled ? disabled : false}
        disableElevation
      >
        {title}{' '}
        <p style={{ color: '#95ACB7', fontSize: '10px' }}>
          {' '}
          {keyboardShortcut ? keyboardShortcut : ''}
        </p>
      </DefaultButton>
    );
  },
);

export default UnfilledButton;
