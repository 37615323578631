import React from 'react';
import history from 'utils/history';

export default function Home({}) {
  React.useEffect(() => {
    history.push('/dashboard');
  }, []);

  return <></>;
}

Home.propTypes = {};
