import React, { forwardRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DefaultButton from '@material-ui/core/Button';
import { COLORS } from '../../constants/colors.js';
import clsx from 'clsx';

const DropdownButton = forwardRef(
  ({
    title,
    icon,
    buttonSize = 'medium',
    executeClick,
    customMargin,
    customHeight,
    customWidth,
    disabled,
  }) => {
    var buttonHeight;
    if (buttonSize === 'small') {
      buttonHeight = '28px';
    } else if (buttonSize === 'medium') {
      buttonHeight = '36px';
    } else if (buttonSize === 'large') {
      buttonHeight = '52px';
    }

    const Button = withStyles(() => ({
      root: {
        color: COLORS.cta_blue,
        fontSize: '11px',
        fontWeight: 500,
        float: 'left',
        width: customWidth ? customWidth : '100%',
        height: customHeight ? customHeight : buttonHeight,
        margin: customMargin ? customMargin : '0px',
        padding: '10px',
        textTransform: 'none',
        borderRadius: '6px',
        backgroundColor: COLORS.white,
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #CAD8DF',
        '&:hover': {
          backgroundColor: '#EAF0F3',
        },
      },
    }))(DefaultButton);

    return (
      <Button
        aria-haspopup="true"
        variant="contained"
        disableElevation
        onClick={executeClick}
        endIcon={icon}
      >
        {title}
      </Button>
    );
  },
);

export default DropdownButton;
