// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Work Sans\",\"Roboto\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}.O9oW9{color:#fff}.qQ2mF{max-height:100%}._11vNT{margin:0 10px;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"root": "O9oW9",
	"container": "qQ2mF",
	"back": "_11vNT"
};
module.exports = exports;
