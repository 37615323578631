import React, { useEffect, useRef, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ToggleCards, ToggleDashboard } from '../../icons/customIcons';
import { Switch } from '@material-ui/core';
import { COLORS } from 'constants/colors';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateHomeStore, resetHomeStore } from 'actions/home';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { Grid } from '@material-ui/core';
import history from 'utils/history';
import s from '../home/Home.css';

const keyMap = {
  sales_card: ['s', 'S'],
  sale_return_card: ['r', 'R'],
  purchase_card: ['p', 'P'],
  purchase_return_card: ['z', 'Z'],
  direct_sales_card: ['d', 'D'],
  inward_card: ['i', 'I'],
  stock_details_card: ['t', 'T'],
  toggle_views: ['Ctrl+b'],
};

const handlers = {
  sales_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/sales');
      else history.push('/retail/sales');
    }
  },
  sale_return_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/sales_return');
      else history.push('/retail/sales_return');
    }
  },
  purchase_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/purchase');
      else history.push('/retail/purchase');
    }
  },
  purchase_return_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/purchase_return');
      else history.push('/retail/purchase_return');
    }
  },
  direct_sales_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/sale_retail');
      else history.push('/retail/direct_sales');
    }
  },
  inward_card: (event) => {
    if (home?.switchView == true) {
      if (branchType === 1) history.push('/inward_order_home');
    }
  },
  stock_details_card: (event) => {
    if (home?.switchView == true) {
      setTimeout(() => {
        history.push('/batch_details');
      }, 100);
    }
  },
  toggle_views: (event) => {
    dispatch(
      updateHomeStore({
        switchView: !home?.switchView,
      }),
    );
  },
};
configure({
  ignoreTags: ['input', 'select', 'textarea'],
  ignoreEventsCondition: (event) => {
    const { target } = event;

    if (target && target.tagName) {
      // const tagName = target.tagName.toLowerCase();

      return target.isContentEditable;
    }
    return false;
  },
});

const getDashboardStyles = (branchType, dashboardAccess) => {
  return {
    marginTop: branchType === 2 && dashboardAccess ? '-50px' : '0px',
    width: '100%',
    height: '170vh',
    // marginLeft: '50px',
    padding: 0,
    flexGrow: 1,
    border: 'none',
    padding: 0,
    borderRadius: '5px',
    overflow: 'hidden',
  };
};

const RenderDashboard = ({ iFrameRef, branchType, dashboardAccess }) => {
  return (
    <iframe
      ref={iFrameRef}
      src={`${window.location.protocol}//${window.location.host}/sp360dashboard`}
      style={getDashboardStyles(branchType, dashboardAccess)}
      // scrolling="no"
    ></iframe>
  );
};

const Dashboard = () => {
  useStyles(s);
  const dispatch = useDispatch();
  const iFrameRef = useRef(null);
  const home = useSelector((state) => state.home);

  const permissions = useSelector((state) => state.permissions);
  const branchType = permissions?.branch_type;

  const [dataStore, setDataStore] = useState({});
  const [dashboardAccess, setDashboardAccess] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      // console.log('Received message from child:', event.data);

      const receivedAction = event?.data?.action;
      const receivedData = event?.data?.data;
      if (receivedAction === 'initialData' || receivedAction === 'updateData') {
        setDataStore((prevDataStore) => {
          const newDataStore = { ...prevDataStore };
          for (const key in receivedData) {
            if (
              key === 'tokenExpired' &&
              receivedData['tokenExpired'] === true
            ) {
              parent.location.hash = '#token_expired';
              return;
            }
            if (receivedData.hasOwnProperty(key)) {
              const value = receivedData[key];
              newDataStore[key] = value;
            }
          }
          return newDataStore;
        });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const triggerSender = () => {
      sendMessage();
    };

    if (iFrameRef.current) {
      iFrameRef.current.addEventListener('load', triggerSender);
    }

    return () => {
      if (iFrameRef.current) {
        iFrameRef.current.removeEventListener('load', triggerSender);
      }
    };
  }, [iFrameRef]);

  const sendMessage = () => {
    if (!iFrameRef.current) return;
    iFrameRef.current.contentWindow.postMessage(
      {
        source: 'parent',
        selectedTradeTab: 0,
        selectedInventoryClosingStockCalcBase: 0,
        selecedInventoryTab: 0,
      },
      '*',
    );
  };

  React.useEffect(() => {
    let dashboardAccessCheck = permissions?.all?.find(
      (perm) =>
        perm?.desc === 'admin_user' ||
        (perm?.desc === 'dashboard' && perm?.access == true),
    );

    if (dashboardAccessCheck) {
      setDashboardAccess(dashboardAccessCheck?.access != 0 ? true : false);
    }
  }, [permissions]);

  return (
    <div>
      {branchType === 2 ? (
        <>
          <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
            <div className={s.container}>
              <Grid
                container
                justify="end"
                style={{ marginTop: '8px', paddingRight: '8px' }}
              >
                <Grid container item md={6}></Grid>
                <Grid
                  container
                  item
                  md={6}
                  justify="flex-end"
                  alignItems="center"
                >
                  {dashboardAccess ? (
                    <CustomSwitch
                      style={{
                        display:
                          !home?.switchView && !dashboardAccess
                            ? 'none'
                            : 'block',
                      }}
                      checked={home?.switchView}
                      icon={
                        <span className={s.toggleThumbContainer}>
                          <ToggleDashboard />
                        </span>
                      }
                      checkedIcon={
                        <span className={s.toggleThumbContainer}>
                          <ToggleCards />
                        </span>
                      }
                      disableRipple
                      onChange={(event) => {
                        dispatch(
                          updateHomeStore({
                            switchView: !home?.switchView,
                          }),
                        );
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>

              {home?.switchView == undefined ? (
                <></>
              ) : home?.switchView ? (
                history.push('/')
              ) : null}
            </div>
          </GlobalHotKeys>
          <RenderDashboard
            iFrameRef={iFrameRef}
            branchType={branchType}
            dashboardAccess={dashboardAccess}
          />
        </>
      ) : (
        <RenderDashboard iFrameRef={iFrameRef} />
      )}
    </div>
  );
};

const CustomSwitch = withStyles({
  root: {
    margin: '0px 20px 0px 0px',
    height: '36px',
    width: '66px',
    padding: '0px',
  },
  switchBase: {
    padding: '2.5px 3px',
    color: COLORS.cta_blue,
    '&$unchecked + $track': {
      backgroundColor: '#fff',
    },
    '&$checked': {
      transform: 'translateX(29px)',
      color: COLORS.cta_blue,
      '& + $track': {
        backgroundColor: '#fff',
        border: '1px solid #CAD8DF',
        opacity: 1,
      },
    },
  },
  checked: {},
  track: {
    borderRadius: '6px',
    backgroundColor: '#fff',
    border: '1px solid #CAD8DF',
    height: 'calc(100% - 2px)',
    opacity: 1,
  },
  thumb: {
    width: '30px',
    height: '30px',
    borderRadius: '6px',
    boxShadow: 'none',
  },
})(Switch);

export default Dashboard;
