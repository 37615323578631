import React from 'react';
import {
  RupeeOutlined,
  CalenderHistory,
  CardOutlined,
  ChequeOutlined,
  PenScribbleOutlined,
  NavBarTools,
  NavBarAnalytics,
  NavBarFinancialAcc,
  NavBarInventory,
  NavBarMasters,
  NavBarReports,
  NavBarUserManagement,
  NavBarRecieptsPayments,
  Keyboard,
  Presentation,
  RightArrow,
  Zap,
  User,
  Template,
  Send,
  Calculator,
  RupeeCircled,
  Print,
  Key,
  Tool,
  Cube,
  HourGlass,
  Globe,
  CSVDownload,
  Approval,
} from 'icons/customIcons';

function renderIcons(iconName) {
  switch (iconName) {
    case 'RupeeOutlined':
      return <RupeeOutlined customStyle={{ marginBottom: '8px' }} />;
    case 'CardOutlined':
      return <CardOutlined customStyle={{ marginBottom: '8px' }} />;
    case 'ChequeOutlined':
      return <ChequeOutlined customStyle={{ marginBottom: '8px' }} />;
    case 'NavBarTools':
      return <NavBarTools />;
    case 'NavBarAnalytics':
      return <NavBarAnalytics />;
    case 'NavBarFinancialAcc':
      return <NavBarFinancialAcc />;
    case 'NavBarInventory':
      return <NavBarInventory />;
    case 'NavBarMasters':
      return <NavBarMasters />;
    case 'NavBarReports':
      return <NavBarReports />;
    case 'NavBarUserManagement':
      return <NavBarUserManagement />;
    case 'NavBarRecieptsPayments':
      return <NavBarRecieptsPayments />;
    case 'Keyboard':
      return <Keyboard />;
    case 'Presentation':
      return <Presentation />;
    case 'RightArrow':
      return <RightArrow />;
    case 'Zap':
      return <Zap />;
    case 'user':
      return <User />;
    case 'template':
      return <Template />;
    case 'send':
      return <Send />;
    case 'calc':
      return <Calculator />;
    case 'rupee':
      return <RupeeCircled />;
    case 'print':
      return <Print />;
    case 'key':
      return <Key />;
    case 'tool':
      return <Tool />;
    case 'cube':
      return <Cube />;
    case 'hourGlass':
      return <HourGlass />;
    case 'globe':
      return <Globe />;
    case 'approval':
      return <Approval />;
    case 'CsvDownload':
      return <CSVDownload />;
    default:
      return '';
  }
}
export default function SVGIcon(props) {
  if (props?.icon) {
    const { icon } = props;
    const TheIcon = icon;
    return <TheIcon {...props} />;
  }
  if (props?.name) {
    return renderIcons(props.name);
  }
}
