import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Grid } from '@material-ui/core';
import CssTextField from 'components/form-fields/CssTextField.js';
import Modal from 'components/Modal/Modal';

function Test({}, ref) {
  useImperativeHandle(
    ref,
    () => ({
      open() {
        setOpen(true);
      },
    }),
    [],
  );

  const [open, setOpen] = useState(false);

  return (
    <Modal
      content={{
        body: (
          <Grid container style={{ padding: '0 16px' }}>
            <CssTextField
              style={{ margin: '8px 0', width: '100%' }}
              margin="normal"
              variant="outlined"
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  if (ev.target.value == 'sp360') {
                    console.log('UNLOCKED');
                  }
                }
              }}
            />
          </Grid>
        ),
      }}
      open={open}
      modalClose={() => {
        setOpen(false);
      }}
      size="xs"
    />
  );
}

export default forwardRef(Test);
